import React from 'react'
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import logo from '../../assests/footer-logo.png'
import { NavLink } from 'react-router-dom';
import { AuthVerify } from '../../utils/auth.utils';
const Footer = () => {
    const verify = AuthVerify();
    return (
        <div>
            <footer className="footer-07 pt-5">
                <Container>
                    <Row className='justify-content-center mx-0'>
                        <Row className="justify-content-center">
                            <Col md={12} className="text-center">
                                <div style={{ margin: "auto" }} className='footer-logo-img'>
                                    <Image src={logo} alt="logo" className='footer-logo' />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-lg-5">
                            <Col md={12} className="text-center pt-4">
                                <p className="poppins-medium subscribe-heading">
                                    Subscribe to get our Newsletter
                                </p>
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-2'>
                            <Col md={5} className='text-center'>
                                <Form>
                                    <Form.Group className="mb-3 d-flex gap-3 justify-content-center align-items-center" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Your Email" className='news-input' />
                                        <Button type="submit" className='news-btn poppins-medium'>
                                            Subscribe
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <>
                                <Col md={4} className='text-center d-flex gap-3 justify-content-lg-between justify-content-center align-items-center pt-4 px-0'>
                                    {verify ? (
                                        <NavLink to={'/help#contactForm'} className='text-decoration-none Footer-link poppins-regular link-btn'>Contact Us</NavLink>
                                    ) : (

                                        <NavLink to={'//contact#contactForm'} className='text-decoration-none Footer-link poppins-regular link-btn'>Contact Us</NavLink>
                                    )}

                                    <div className='line'></div>
                                    <NavLink to={'/terms-and-conditions'} className='text-decoration-none Footer-link poppins-regular link-btn'>Terms & Conditons</NavLink>
                                </Col>
                            </>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12} className="text-center">
                                <p className="Footer-link poppins-regular">
                                    © 2024 MYOFLOW
                                </p>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </footer>
        </div >
    )
}

export default Footer
