import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Image, ProgressBar, Row, Tab, Tabs } from 'react-bootstrap';
import button from '../assests/Button.svg';
import folder from '../../src/assests/FolderNotchOpen.svg';
import Videoplayicon from '../assests/Play.svg';
import playicon from '../../src/assests/PlayCircle.svg';
import clock from '../../src/assests/Clock.svg';
import Description from '../components/Description/Description';
import LectureNotes from '../components/LectureNotes/LectureNotes';
import AttachFile from '../components/Attach/AttachFile';
import CourseHeading from '../components/sharedComponent/CourseHeading';
import Accordian from '../components/Curriculum/Accordian';
import coursePlay from '../assests/Play (1).svg';
import FeedbackModal from '../components/Modals/FeedbackModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetLectureById, GetLectureForUser, updatedProgress } from '../services/lectures/lectures.service';
import { DeleteCourse, GetSection, PublishCourse } from '../services/createCourse/createCourse.service';
import { toast } from 'react-toastify';
import { HiOutlineTrash } from 'react-icons/hi';
import DeleteModal from '../components/Modals/DeleteModal ';
import { getCertficate } from '../services/userDashboard/allCourses.service';
import placeholderVideo from "../assests/vedio/not-found.mp4";
import CertificateModal from '../components/Modals/CertificateModal';
import CourseContentOffcanvas from '../components/CourseContent/CourseContentOffcanvas';

const CourseContent = ({ roles }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { courseId } = location.state || {};
    const { id } = useParams();
    const isAdmin = roles?.includes('admin');
    const [isPlaying, setIsPlaying] = useState(false);
    const [sections, setSections] = useState({});
    const [sectionLength, setSectionLength] = useState();
    const [key, setKey] = useState('description');
    const [showModal, setShowModal] = useState(false);
    const [lectureId, setLectureId] = useState();
    const [selectedLectureId, setSelectedLectureId] = useState();
    const [lecture, setLecture] = useState();
    const [loading, setLoading] = useState(false);
    const [updatingProgress, setUpdatingProgress] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [courseDetails, setCourseDetails] = useState({ progress: 0, sections: [], completedLectures: [], isCourseCompleted: false });
    const videoRef = useRef(null);
    const [isPublished, setIsPublished] = useState();
    const [currentSection, setCurrentSection] = useState();
    const [showCertificateModal, setShowCertificateModal] = useState(false);
    const [certificate, setCertificate] = useState(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const togglePlay = () => {
        const video = videoRef.current;
        video.play();
        setIsPlaying(true);
    };

    const handleEdit = () => {
        navigate('/all-courses/edit-course', { state: { id: courseId } });
    };

    const completeSectionAndUpdateProgress = async (courseId, lectureId, isCompleted) => {
        try {
            setUpdatingProgress(true);
            const response = await updatedProgress({
                courseId, currentSection, lectureId, isCompleted
            });
            setUpdatingProgress(false);
            return response.data;
        } catch (error) {
            console.error("Error updating progress:", error);
            setUpdatingProgress(false);
            throw error;
        }
    };

    const handleVideoEnded = async () => {
        setIsPlaying(false);
        try {
            const data = await completeSectionAndUpdateProgress(courseId, lectureId, true);
            await fetchIspublishedCourse();

            // Logic to move to the next lecture
            const allLectures = sections?.sections?.flatMap(section => section.lectures) || [];
            const currentIndex = allLectures.findIndex(lecture => lecture._id === lectureId);
            const nextLecture = allLectures[currentIndex + 1];

            if (nextLecture) {
                setLectureId(nextLecture._id);
                setSelectedLectureId(nextLecture._id);
            }
        } catch (error) {
            console.error("Error updating progress:", error);
        }
    };

    const handleVideoPause = () => {
        const video = videoRef.current;
        video.pause();
        setIsPlaying(false);
    };

    const goBack = () => {
        window.history.back();
    };

    const fetchLectureById = async () => {
        setLoading(true);
        try {
            const response = await GetLectureById(lectureId);
            setLecture(response?.data);
            setCurrentSection(response?.data?.sectionId);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching lecture:", error);
        }
    };

    const fetchIspublishedCourse = async () => {
        try {
            setLoading(true);
            const sectionResponse = await GetSection(id);
            setSections(sectionResponse?.data);
            setSectionLength(sectionResponse?.data);
            setIsPublished(sectionResponse?.data?.isPublished);
            if (!isAdmin) {
                const lectureResponse = await GetLectureForUser(courseId);
                console.log('lectureResponse', lectureResponse)
                setCourseDetails(lectureResponse.data.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching sections:", error);
        }
    };

    useEffect(() => {
        fetchIspublishedCourse();
    }, [isPublished, id]);

    useEffect(() => {
        if (lectureId) {
            fetchLectureById();
        }
    }, [lectureId]);

    useEffect(() => {
        if (sections?.sections?.length > 0 && sections.sections[0]?.lectures?.length > 0) {
            setLectureId(sections.sections[0].lectures[0]._id);
            setSelectedLectureId(sections.sections[0].lectures[0]._id);
        }
    }, [sections]);

    const handleLectureChange = async (id, checked) => {
        try {
            const data = await completeSectionAndUpdateProgress(courseId, id, checked);
            await fetchIspublishedCourse();
            setLectureId(id);
            setSelectedLectureId(id);
        } catch (error) {
            console.error("Error updating lecture completion status:", error);
        }
    };

    const handleLectureClick = (id) => {
        setLectureId(id);
        setSelectedLectureId(id);
    };

    const handleNextLecture = () => {
        const allLectures = sections?.sections?.flatMap(section => section.lectures) || [];
        const currentIndex = allLectures.findIndex(lecture => lecture._id === selectedLectureId);
        const nextLecture = allLectures[currentIndex + 1];

        if (nextLecture) {
            setLectureId(nextLecture._id);
            setSelectedLectureId(nextLecture._id);
        }
    };

    const handleGetCertificate = async () => {
        try {
            const response = await getCertficate(courseId);
            setCertificate(response.data.certificate);
            setShowCertificateModal(true);
        } catch (error) {
            console.error('Error getting certificate:', error);
            toast.error('Failed to get certificate');
        }
    };

    const confirmDelete = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Deleting", { closeButton: true });
        try {
            await DeleteCourse(courseId);
            toast.update(toastId, {
                render: "Course deleted successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            navigate('/all-courses');
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.error("Error deleting item:", error);
        }
    };

    const handlePublish = async (e) => {
        e.preventDefault();
        const action = isPublished ? 'unpublished' : 'published';
        const actionload = isPublished ? 'Unpublishing' : 'Publishing';
        const toastId = toast.loading(`${actionload}`, { closeButton: true });
        try {
            await PublishCourse(courseId, !isPublished);
            toast.update(toastId, {
                render: `Course ${action} successfully`,
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            fetchIspublishedCourse();
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.error("Error publishing course:", error);
        }
    };

    const handleDelete = (type, id) => {
        setDeleteTarget({ type, id });
        setShowDeleteModal(true);
    };

    const calculateProgress = (completedLectures, sections) => {
        const totalLectures = sections.reduce((acc, section) => acc + section.lectures.length, 0);
        return (completedLectures.length / totalLectures) * 100;
    };

    const convertTime = (decimalMinutes) => {
        if (!decimalMinutes || isNaN(decimalMinutes)) {
            return '0:00';
        }

        const totalSeconds = Math.floor(decimalMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div>
            <div className='mx-0 content-bg py-3 d-flex flex-xl-row flex-column align-items-xl-center justify-content-between gap-xl-0 gap-3 mt-xl-0 mt-2'>
                <div className='d-flex'>
                    <div className='d-flex align-items-xl-center gap-2 ps-xl-3 ps-2 pe-1'>
                        <div onClick={goBack} style={{ cursor: 'pointer' }}>
                            <Image className='back-img' src={button} />
                        </div>
                        <div>
                            <p className='mb-0 poppins-medium text-small'>{sections?.title}</p>
                            <div className='mx-0 d-flex gap-2 align-items-center'>
                                <div className='px-0'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div>
                                            <Image className='' src={folder} />
                                        </div>
                                        <div className='lecture text-small'>
                                            {sectionLength?.sections?.length} sections
                                        </div>
                                    </div>
                                </div>
                                <div className='px-0'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div>
                                            <Image className='' src={playicon} />
                                        </div>
                                        <div className='lecture text-small'>
                                            {sectionLength?.totalLectureCount} lectures
                                        </div>
                                    </div>
                                </div>
                                <div className='px-0'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div>
                                            <Image className='' src={clock} />
                                        </div>
                                        <div className='lecture text-small'>
                                            {(sectionLength?.totalCourseDuration ?? 0).toFixed(2)}m
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2 pe-md-3 justify-content-md-end justify-content-center'>
                    {isAdmin ? (
                        <>
                            <Button className='d-flex align-items-center gap-4 bg-white primary-color border-white review-btn' onClick={handleEdit}>
                                <div className='poppins-semibold text-small'>Edit Course</div>
                            </Button>
                            <Button className='d-flex align-items-center gap-4 login-button primary-bg' onClick={handlePublish}>
                                <div className='poppins-semibold start-btn-text text-small'>{isPublished ? 'Send to Draft' : 'Publish Course'}</div>
                            </Button>
                            <Button className='d-flex align-items-center gap-4 bg-white primary-color border-white review-btn'>
                                <div className='poppins-semibold text-small'>
                                    <HiOutlineTrash className='icon-color-delte delete-section-icon' onClick={() => handleDelete('course', courseId)} />
                                </div>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button className='d-flex align-items-center gap-4 bg-white primary-color border-white review-btn' onClick={() => setShowModal(true)}>
                                <div className='poppins-semibold text-small'>Write A Review</div>
                            </Button>
                            <Button className='d-flex align-items-center gap-4 login-button primary-bg' onClick={handleNextLecture}>
                                <div className='poppins-semibold start-btn-text text-small'>Next Lecture</div>
                            </Button>
                            {courseDetails.progress === 100 && (
                                <Button className='d-flex align-items-center gap-4 login-button primary-bg' onClick={handleGetCertificate}>
                                    <div className='poppins-semibold start-btn-text text-small'>Get Certificate</div>
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className='pt-2'>
                {sections?.sections?.length === 0 ? (
                    <div className='pt-4 px-4'>No lectures available in this section.</div>
                ) : (
                    <Row className='flex-column-reverse flex-xl-row allign-items-center'>
                        <Col Col md={12}>
                            {loading || updatingProgress ? (
                                <div className="loader-block">
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <>
                                    <div className="video-placeholder mt-4 card bg-dark">
                                        <video
                                            ref={videoRef}
                                            controls={isPlaying}
                                            src={lecture?.video[0]?.url || placeholderVideo}
                                            style={{ width: "100%", height: "auto", maxHeight: "550px", objectFit: "contain" }}
                                            onEnded={handleVideoEnded}
                                            onPause={handleVideoPause}
                                        />
                                        {!isPlaying && (
                                            <div className='Player-btn' onClick={togglePlay}>
                                                <Image className='' src={Videoplayicon} />
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-between py-4 flex-md-row flex-column-reverse gap-md-0 gap-3'>
                                        <div className=''>
                                            <h3 className='poppins-bold course-heading'>{lecture?.name}</h3>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <Button className='d-flex align-items-center gap-4 login-button primary-bg me-lg-0 me-2' onClick={() => setShowOffcanvas(true)}>
                                                <div className='poppins-semibold start-btn-text text-small'>View Course Content</div>
                                            </Button>
                                        </div>
                                    </div>
                                    <Row className='m-0 p-0 '>
                                        <Col md={8} className='m-0 p-0 custom-tabs'>
                                            <Tabs
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}
                                                id="fill-tab-example"
                                                className="mb-3 tab-text"
                                                fill
                                            >
                                                <Tab eventKey="description" title="Description">
                                                    <Description lecture={lecture} />
                                                </Tab>
                                                <Tab eventKey="lectre" title="Lectures Notes">
                                                    <LectureNotes lecture={lecture} />
                                                </Tab>
                                                <Tab eventKey="attach" title="Attached Files">
                                                    <AttachFile lecture={lecture} />
                                                </Tab>
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
            <FeedbackModal
                courseId={courseId}
                onHide={() => setShowModal(false)}
                show={showModal}
            />
            <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={confirmDelete}
                targetType={deleteTarget?.type}
            />
            <CertificateModal
                show={showCertificateModal}
                onHide={() => setShowCertificateModal(false)}
                certificate={certificate}
            />
            <CourseContentOffcanvas
                show={showOffcanvas}
                handleClose={() => setShowOffcanvas(false)}
                isAdmin={isAdmin}
                sections={sections}
                courseDetails={courseDetails}
                selectedLectureId={selectedLectureId}
                handleLectureChange={handleLectureChange}
                handleLectureClick={handleLectureClick}
                loading={loading}
                updatingProgress={updatingProgress}
            />
        </div>
    );
};

export default CourseContent;
