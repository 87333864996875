import React, { useRef, useState } from 'react';
import './certificate.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Certificate = ({ firstname, lastname, coursename, date }) => {
    const [loading, setLoading] = useState(false);
    const certificateRef = useRef();

    const handleDownload = () => {
        setLoading(true);
        const certificateElement = certificateRef.current;
        const originalTransform = certificateElement.style.transform;
        certificateElement.style.transform = 'none';

        html2canvas(certificateElement).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [canvas.width, canvas.height],
            });
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(`${firstname}_${lastname}_certificate.pdf`);
            certificateElement.style.transform = originalTransform;
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            certificateElement.style.transform = originalTransform;
            console.error("Error generating PDF: ", error);
        });
    };

    return (
        <div className='position-relative'>
            {loading ? (
                <div className="loader-block">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <div className='certificate-body' ref={certificateRef} style={{ transform: 'scale(0.75)', transformOrigin: 'top' }}>
                        <div className="container pm-certificate-container" id='certificate'>
                            <div className="outer-border"></div>
                            <div className="inner-border"></div>
                            <div className="pm-certificate-border col-12">
                                <div className="row pm-certificate-header">
                                    <div className="pm-certificate-title cursive col-12 text-center">
                                        <h2 className='certificate-title'>Certificate of Completion</h2>
                                    </div>
                                </div>
                                <div className="row pm-certificate-body">
                                    <div className="pm-certificate-block">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-2"></div>
                                                <div className="pm-certificate-name underline margin-0 col-8 text-center">
                                                    <span className="pm-name-text bold"> {firstname} {lastname}</span>
                                                </div>
                                                <div className="col-2"></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-2"></div>
                                                <div className="pm-earned col-8 text-center">
                                                    <span className="pm-earned-text padding-0 block cursive">has successfully completed</span>
                                                    <span className="pm-credits-text block bold sans py-3">{coursename}</span>
                                                </div>
                                                <div className="col-2"></div>
                                                <div className="col-12"></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-2"></div>
                                                <div className="pm-course-title col-8 text-center">
                                                    <span className="pm-earned-text block cursive">an online non-credit course authorized by My COGNITY</span>
                                                </div>
                                                <div className="col-2"></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row justify-content-center">
                                                <div className="pm-course-title underline col-8 text-center"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4 position-absolute start-0 end-0 pdf-button">
                        <button className="btn btn-primary" onClick={handleDownload}>Download PDF</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Certificate;
