import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { uploadUserFile } from '../../services/TeacherDashboard/Dashboard.service';

const FileUpload = ({ show, onHide, onSave }) => {
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const handleSave = () => {
        if (files.length > 0) {
            onSave(files);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Uploading", { closeButton: true });
        const uploadedFiles = e.target.files ? Array.from(e.target.files) : Array.from(e.dataTransfer.files);
        e.target.value = null;
        if (uploadedFiles.length > 0) {
            try {
                const newFiles = [];
                const newFileNames = [];
                for (const uploadedFile of uploadedFiles) {
                    const formData = new FormData();
                    formData.append('attachments', uploadedFile);
                    const response = await uploadUserFile(formData);
                    const newFileSrc = response?.data?.data[0];
                    newFiles.push(newFileSrc);
                    newFileNames.push(uploadedFile.name);
                }
                setFiles(newFiles);
                setFileNames(newFileNames);
                toast.dismiss(toastId);
                setIsUploaded(true);
                setIsLoading(false);
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setIsLoading(false);
            }
        }
    };

    return (
        <div>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='feedback-header'>Attach File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isUploaded ? (
                        <Form.Group>
                            <div
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                style={{
                                    border: '1px solid #E9EAF0',
                                    borderRadius: '6px',
                                    padding: '24px',
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}
                                onClick={() => document.getElementById('FileInputUpload').click()}
                            >
                                <p className='text-dark course-para-light mb-1'>Attach Files</p>
                                <p className=' course-para-light'>Drag and drop files or browse files</p>
                                <input
                                    id="FileInputUpload"
                                    type="file"
                                    accept="application/pdf,.csv,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    multiple
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </Form.Group>
                    ) : (
                        <>
                            <p className='text-success mb-1'>Files Uploaded Successfully</p>
                            {fileNames.map((fileName, index) => (
                                <p key={index} className='text-dark mb-1'>{fileName}</p>
                            ))}
                            <button
                                variant="link"
                                className="p-0 primary-color bg-white border-0 text-decoration-none pt-2"
                                onClick={() => {
                                    setFiles([]);
                                    setIsUploaded(false);
                                    setFileNames([]);
                                }}
                            >
                                Replace Files
                            </button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={onHide} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={isLoading || files.length === 0}
                        className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                    >
                        Attach Files
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FileUpload;
