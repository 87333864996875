import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormLabel, Row, Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import contact from '../assests/contact (2).png'
import { contactForm } from '../services/contact/contact.service';
import { useLocation } from 'react-router-dom';
import { FaLinkedin, FaTwitter } from 'react-icons/fa6';

const Contact = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    const location = useLocation();
    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('');
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handleContinueContact = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setNameError('');
        setEmailError('');
        let isValid = true;

        if (name.trim() === '') {
            setNameError('Please enter your first name.');
            isValid = false;
        }
        // if (lastName.trim() === '') {
        //     setLastNameError('Please enter your last name.');
        //     isValid = false;
        // }
        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        if (message.trim() === '') {
            setMessageError('Please enter your message.');
            isValid = false;
        }

        if (isValid) {
            const toastId = toast.loading("Sending Message", { closeButton: true });
            try {
                const response = await contactForm({
                    firstname: name,
                    lastname: lastName,
                    email: email,
                    message: message
                });
                toast.update(toastId, {
                    render: "Message is sent Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                const user = response.data.data;
                localStorage.setItem("user", JSON.stringify(user));
                setName('')
                setLastName('')
                setEmail('')
                setMessage('')
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <Container fluid className='py-5 pt-3 px-2 px-xl-5' >
            <div className='px-2 px-xl-5'>
                <div className='px-lg-5'>
                    <div className='px-lg-5'>
                        <div className='pb-4' id='contactForm'>
                            <h2 className='poppins-bold contact-heading'>Contact  Us</h2>

                        </div>
                        <Row className='justify-content-between gap-xl-0 gap-md-0 gap-3'>
                            <Col xl={5} md={7}>  <p className='mb-3'>We'd love to hear from you. Fill out the form below or reach us directly through our contact details.
                            </p>
                                <Form className='custom-form'>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail" className='pb-3'>
                                                <FormLabel className='font-14' >First Name</FormLabel>
                                                <Form.Control type="text" placeholder="First Name" value={name} onChange={handleNameChange} />
                                                <Form.Text className="text-danger">{nameError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicLastName" className='pb-3'>
                                                <FormLabel className='font-14' >Last Name</FormLabel>
                                                <Form.Control type="text" value={lastName} placeholder="Last Name" onChange={handleLastNameChange} />
                                                <Form.Text className="text-danger">{lastNameError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasicEmail" className='pb-3'>
                                                <FormLabel className='font-14' >Email</FormLabel>
                                                <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                                <Form.Text className="text-danger">{emailError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasicEmail" className='pb-3'>
                                                <FormLabel className='font-14' >What can we help you with ?</FormLabel>
                                                <Form.Control value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Type here your message' as="textarea" rows={5} />
                                                <Form.Text className="text-danger">{messageError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Button variant="primary" onClick={handleContinueContact} className='w-100 primary-bg login-button py-2 font-14 my-1 my-lg-0'>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col xl={7} md={5}>
                                <div className='image-responsive-cognite-contact pt-lg-0 pt-4 mx-auto d-md-block d-none' style={{}}>
                                    <Image src={contact} className='Cognite-img' alt='Image' />
                                </div>
                            </Col>
                        </Row>
                        <Container fluid className="text-center py-5">
                            <h4>Connect with us on Social Media</h4>
                            <Row className="justify-content-center mt-4">
                                <Col xs="auto">
                                    <a
                                        href="https://www.linkedin.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block mx-2"
                                    >
                                        <FaLinkedin size={30} className="primary-color hover-secondary" />
                                    </a>
                                </Col>
                                <Col xs="auto">
                                    <a
                                        href="https://twitter.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="d-inline-block mx-2"
                                    >
                                        <FaTwitter size={30} className="primary-color hover-secondary" />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Contact;
