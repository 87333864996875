import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assests/header-logo.png";
import { Container, Spinner } from "react-bootstrap";
import b1 from '../assests/images/b1 (1).jpg'
import b2 from '../assests/images/b1 (2).jpg'
import b3 from '../assests/images/b1 (3).jpg'
// Static data array
const blogsData = [
  {
    id: "1",
    title: "The Benefits of Online Health Courses",
    banner: b1,
    excerpt: "Explore the advantages of taking health and wellness courses online, including flexibility, access to expert instructors, and the ability to learn at your own pace.",
  },
  {
    id: "2",
    title: "Nutrition Tips for a Healthier Life",
    banner: b2,
    excerpt: "Share expert advice on nutrition, including tips for maintaining a balanced diet, meal planning, and how proper nutrition can enhance physical and mental well-being.",
  },
  {
    id: "3",
    title: "How AI is Revolutionizing Fitness Training",
    banner: b3,
    excerpt: "Discuss how AI and machine learning are transforming personalized fitness training, making it more effective and enjoyable for users.",
  },
];

const Blogs = () => {
  const [apiBlogs, setApiBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiBlogs(blogsData);
    setLoading(false);
  }, []);

  return (
    <>

      <Container>
        <div className="blogheading pt-1">
          <h1>Blogs</h1>
        </div>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          apiBlogs?.map((blogItem) => (
            <Link
              to={`/blogdetails/${blogItem.id}`}
              key={blogItem.id}
              style={{ textDecoration: "none" }}
            >
              <div
                className="card my-3 border-0 bg-transparent"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                <div className="row no-gutters align-items-center ">
                  <div className="col-md-5 col-12  pr-0" style={{ height: "auto" }}>
                    <div className="blogimg">
                      <img
                        src={blogItem.banner}
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "cover" }}
                        alt={blogItem.title}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 col-12  pl-0">
                    <div className="card-body">
                      <h5 className="card-title">{blogItem.title}</h5>
                      <p className="card-text">
                        {blogItem.excerpt?.length > 60
                          ? blogItem.excerpt.substring(0, 60) + "..."
                          : blogItem.excerpt}
                      </p>
                      <div className="d-flex pb-2">
                        <div
                          className="mt-1"
                          style={{
                            width: "60px",
                            height: "40px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={logo}
                            alt="Logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="px-2">
                          <p className="m-0">
                            <i style={{ color: "#EF6837", fontSize: "14px" }}>
                              Author
                            </i>
                          </p>
                          <small>
                            <b className="secondary-color" style={{ fontSize: "12px" }}>
                              MYOFLOW
                            </b>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        )}
      </Container>
    </>
  );
};

export default Blogs;
