import React, { useRef } from 'react'
import { Card, Carousel, Image, } from 'react-bootstrap'
import testimonial1 from '../../assests/Mask Group.png'
import right from '../../assests/right-icon.svg'
import star from '../../assests/Star 1.svg'
const CaresoulReview = () => {
    const cardData = [
        {
            text: "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. MYOFLOW is exactly what our business has been lacking.",
            img: testimonial1,
            name: 'Gloria Rose',
            stars: 5
        },
        {
            text: "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. MYOFLOW is exactly what our business has been lacking.",
            img: testimonial1,
            name: 'Gloria Benz',
            stars: 3
        },
        // Add more card data as needed
    ];
    const carouselRef = useRef(null);

    // const handlePrev = () => {
    //     if (carouselRef.current) {
    //         carouselRef.current.prev();
    //     }
    // };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    return (
        <>
            <Carousel indicators={false} controls={false} interval={null} ref={carouselRef} className='custom-carousel'>
                {cardData.map((card, index) => (
                    <Carousel.Item key={index} className='custom-carousel-item'>
                        <>
                            <div className='image-responsive-testimonial mx-auto d-flex align-items-center' style={{}}>
                                <Image src={card.img} className='Cognite-img' height={"100%"} width={"100%"} alt='Image' />
                                <div className='btn-right' style={{ cursor: "pointer" }} onClick={handleNext}>
                                    <Image src={right} alt='Image' />
                                </div>
                            </div>
                            <div className='review-card'>
                                <Card className='pt-0 px-2 testi-card pt-lg-4'>
                                    <Card.Body className=''>
                                        <Card.Text className='card-text-testi px-2'>
                                            {card.text}
                                        </Card.Text>
                                        <div className='d-flex align-items-center justify-content-between px-lg-4 px-2'>
                                            <h2 className='comment-name mb-0'>{card.name}</h2>
                                            <div>
                                                <div className='text-center'>
                                                    {Array.from({ length: card.stars }, (_, i) => (
                                                        <Image key={i} src={star} width={'24px'} height={'24px'} className='star-img' alt='star' />
                                                    ))}
                                                </div>
                                                <p className='comment-text border-0 mb-0'>
                                                    12 reviews at Yelp
                                                </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                    </Carousel.Item>
                ))}

            </Carousel>
        </>
    )
}

export default CaresoulReview
