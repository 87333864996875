import React, { useEffect, useState } from 'react'
import Heading from '../../components/sharedComponent/Heading'
import profile from '../../assests/Ellipse 8.png'
import background from '../../assests/overlaptop.png'
import { Col, Row } from 'react-bootstrap'
import CourseCard from '../../components/sharedComponent/CourseCard'
import { GetAllCourses } from '../../services/createCourse/createCourse.service'
const MyCourses = () => {
    const [course, setCourse] = useState();
    const [loading, setLoading] = useState(true);
    const fetchAllCourses = async () => {
        try {
            const response = await GetAllCourses();
            console.log("response ", response?.data?.data)
            // setSections(response?.data?.sections);
            setCourse(response?.data?.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching sections:", error);
        }
    };
    const getUsername = (email) => {
        if (!email) return '';
        return email.split('@')[0] + '@';
    };
    useEffect(() => {
        fetchAllCourses();
    }, []);
    return (
        <div className='py-4'>
            <Heading heading='My Courses' />
            {loading ? (
                <div className="loader-block">
                    <div className="loader"></div>
                </div>
            ) : (
                <div className='pt-3'>
                    <Row className='gap-xl-0 gap-lg-0 gap-md-0 gap-3'>
                        {course?.map((course, courseIndex) => (

                            <Col key={courseIndex} lg={6} xl={4} md={6} className='mb-4'>
                                <CourseCard
                                    profile={course?.profile || profile}
                                    backgroundImage={course?.thumbnail || background}
                                    name={course.author}
                                    username={getUsername(course.authorEmail)}
                                    time={course.isPublished ? "Published" : "Draft"}
                                    description={course?.topic}
                                    title={course?.title}
                                    buttonName="View Course"
                                    fontSize={10}
                                    className='min-Height'
                                    color='#0a66c2'
                                    courseID={course?._id}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </div>
    )
}

export default MyCourses
