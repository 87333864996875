import React, { useState } from "react";
import { Accordion, Card, Image } from "react-bootstrap";
import AccordionButton from 'react-bootstrap/AccordionButton'
const Accordian = (props) => {
    const [accordionState, setAccordionState] = useState(true);

    const handleAccordionClick = () => {
        setAccordionState(!accordionState);
    };

    const accordionTitle = {
        fontSize: "12px",
        cursor: "pointer",
    };

    const accordionContent = {
        padding: "0px",
    };
    const calculatePercentage = () => {
        if (props.total === 0) {
            return 0;
        }
        return (props.completed / props.total) * 100;
    };

    const percentage = calculatePercentage();
    return (
        <Accordion defaultActiveKey={accordionState ? "0" : null} className="accordian-custom mb-2">
            <Card className="border-0">
                <Accordion.Header className="d-flex justify-content-between align-items-center custome-accordian-btn" onClick={handleAccordionClick} style={accordionTitle}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className="d-flex align-items-center gap-2">
                            <AccordionButton as={'button'} className="accordion-button-custome" />
                            <div className="accordian-title">
                                {props.title}
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end">
                            <div className='px-0'>
                                <div className='d-flex align-items-center gap-1'>
                                    <div>
                                        <Image className='' src={props.playIcon} />
                                    </div>
                                    <div className="accordian-text">
                                        {props.lectureCount} lectures
                                    </div>
                                </div>
                            </div>
                            <div className='px-0'>
                                <div className='d-flex align-items-center gap-1'>
                                    <div>
                                        <Image className='' src={props.clockIcon} />
                                    </div>
                                    <div className="accordian-text">
                                        {props.time}
                                    </div>
                                </div>
                            </div>
                            {props.checkIcon && (
                                <div className='px-0'>
                                    <div className='d-flex align-items-center gap-1'>
                                        <div>
                                            <Image className='' src={props.checkIcon} />
                                        </div>
                                        <div className="accordian-text">
                                            <span>{percentage}% finish <span className="count">({props.completed}/{props.total})</span></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body style={accordionContent}>
                    {props.children}
                </Accordion.Body>
            </Card>
        </Accordion>
    );
}

export default Accordian;
