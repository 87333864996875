import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Heading from '../components/sharedComponent/Heading';
import { Col, Row, Spinner } from 'react-bootstrap';
import profile from '../assests/Ellipse 8.png';
import complete from '../assests/completed.svg';
import background from '../assests/overlaptop.png';
import CourseCard from '../components/sharedComponent/CourseCard';
import { getCompletedCourses } from '../services/userDashboard/allCourses.service';

const CompletedCourse = () => {
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCourses = async () => {
        try {
            const response = await getCompletedCourses();
            setCourses(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    return (
        <div className='py-4'>
            <Heading heading='Completed' />
            <div className='pt-3'>
                {isLoading ? (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <Row className='gap-xl-0 gap-lg-0 gap-md-0 gap-3'>
                        {courses.map(course => (
                            <Col key={course._id} lg={6} xl={4} md={6} className='mb-4'>
                                <CourseCard
                                    profile={course?.courseId?.thumbnail}
                                    courseID={course?.courseId?._id}
                                    backgroundImage={course?.courseId?.thumbnail}
                                    certficate={complete}
                                    name={course?.courseId?.author}
                                    username={course?.userId}
                                    time={course?.courseId?.duration}
                                    description={course?.courseId?.description}
                                    buttonName="Course Completed"
                                    fontSize={10}
                                    className='min-Height'
                                    color='#0a66c2'
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            </div>
        </div>
    );
};

export default CompletedCourse;
