import React from 'react'

const Heading = ({ heading }) => {
    return (
        <div>
            <h3 className='poppins-bold' style={{ color: "#292D32" }}>{heading}</h3>
        </div>
    )
}

export default Heading
