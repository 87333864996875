import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { menuItem } from './MenuItems';
import { Button, Card } from 'react-bootstrap';
import image from "../../assests/computer.svg"
import { GetUserRoles } from '../../utils/auth.utils';

const Sidebar = ({ isOpen }) => {
    const userRoles = GetUserRoles();
    const navigate= useNavigate();
    const isVisible = (visibleOn) => {
        return visibleOn.some(role => userRoles.includes(role));
    };
    return (
        <div style={{ minWidth: isOpen ? "330px" : "100px" }} className="sidebar pt-5 d-lg-block d-none">
            <div className='d-flex flex-column gap-3 py-4 activeLink' style={{ backgroundColor: '#F5EFFE' }}>
                {
                    menuItem.map((item, index) => (
                        isVisible(item.visibleOn) && (
                            <NavLink to={item.path} key={index} className="link poppins-regular" activeclassname="activeLink">
                                <div className="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        )
                    ))
                }
            </div>
            {isOpen ?
                <Card className='mt-3 sidecard poppins-regular' style={{ width: '80%', margin: "auto", background: "#2C3E50", borderRadius: "32px", overflow: "hidden", display: isOpen ? "block" : "none" }}>

                    <Card.Body className='text-white p-4 pb-0'>
                        <Card.Title >Support 24/7</Card.Title>
                        <Card.Text>
                            Contact us anytime
                        </Card.Text>
                        <Button onClick={()=>navigate("/help#contactForm")} variant="primary">Start</Button>

                    </Card.Body>
                    <div style={{ width: "70%", marginLeft: "auto" }}>
                        <Card.Img style={{ width: "100%" }} src={image} />
                    </div>

                </Card>
                : ""}
        </div>
    );
};

export default Sidebar;