import React, { useState } from 'react'
import { Button, Col, Container, Form, Image, Row, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { ResetUserPassword } from '../services/login/login.service';
import logo from '../assests/logo.png'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BiHide, BiShow } from 'react-icons/bi';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');
    const location = useLocation();
    const { email } = location.state || {};
    const { otp } = location.state || {};
    const navigate = useNavigate();
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setconfirmPasswordError('');
    };
    const validatePassword = (password) => {
        // Password validation regex
        return password.length >= 8 && password.length <= 20;
    };
    const validateConfirmPassword = (confirmPassword) => {
        // Password validation regex
        return password === confirmPassword;
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleContinue = async (e) => {
        e.preventDefault();
        setPasswordError('');
        setconfirmPasswordError('');

        let isValid = true;

        // Validate confirm password
        if (!validateConfirmPassword(confirmPassword)) {
            setconfirmPasswordError('Password and Confirm Password should Match.');
            isValid = false;
        }

        // Validate password
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters,must contain at least one uppercase and one special character.');
            isValid = false;
        }

        if (isValid) {
            const toastId = toast.loading("Reseting Password", { closeButton: true });
            try {
                await ResetUserPassword({
                    code: otp,
                    email: email,
                    password: password,
                });
                toast.update(toastId, {
                    render: "Password is Succesfully Reset",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                navigate('/login');
            } catch (error) {
                console.log("Password do not match", error);
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    // useEffect(() => {
    //     console.log('Email', email);
    //     console.log('Otp', otp);
    // }, []);
    return (
        <div>
            <div className="login-bg d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={4} md={8}>
                            <div style={{ margin: "auto", width: "200px", marginBottom: "50px" }}>
                                <Image src={logo} alt="logo" />
                            </div>
                            <div className='sign-heading text-center pb-3'>
                                <p className='poppins-semibold font-28 mb-1'>Reset Password</p>
                            </div>
                            <Form>
                                <Form.Group controlId="formBasicPassword" className='pb-3'>
                                    <Form.Label className='font-14'>Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            aria-label="New Password"
                                            aria-describedby="basic-addon2"
                                        />
                                        <InputGroup.Text onClick={togglePasswordVisibility}>
                                            {showPassword ? <BiHide /> : <BiShow />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text className="text-danger">{passwordError}</Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword" className='pb-3'>
                                    <Form.Label className='font-14'>Re-enter Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder="Re-enter Password"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            aria-label="Confirm Password"
                                            aria-describedby="basic-addon3"
                                        />
                                        <InputGroup.Text onClick={toggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? <BiHide /> : <BiShow />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>
                                </Form.Group>
                                <Button variant="primary" type="button" onClick={handleContinue} className='w-100 primary-bg login-button py-2 font-14'>
                                    Continue
                                </Button>
                            </Form>
                        </Col>
                    </Row >
                </Container >
            </div >
        </div>
    )
}

export default ResetPassword
