import React from 'react';
import { Form, Image } from 'react-bootstrap'; // Assuming you're using react-bootstrap for Image component

const Lecture = ({ label, value, checked, disabled, isCompleted, onChange, coursePlaySrc, courseName, time, timeplay, onClick, manualToggle }) => {
    const handleChange = (e) => {
        if (manualToggle) {
            onChange(value, e.target.checked);
        }
    };

    const activeStyle = {
        backgroundColor: '#f0f8ff', // Light color to distinguish active lecture
    };

    return (
        <div
            className='py-4 px-4 d-flex justify-content-between align-items-center'
            style={{ cursor: onClick ? 'pointer' : 'default', ...(checked && activeStyle) }}
            onClick={onClick}
        >
            <div className='d-flex align-items-center gap-2' style={{ flexGrow: 1 }}>
                {coursePlaySrc && (
                    <div>
                        <Image className='' src={coursePlaySrc} />
                    </div>
                )}
                {label && (
                    <Form.Group controlId={value} className=''>
                        <Form.Check
                            className='custom-check d-flex align-item-center mb-0'
                            type="checkbox"
                            label={label}
                            disabled={disabled}
                            value={value}
                            checked={isCompleted}
                            onChange={handleChange}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Form.Group>
                )}
                <div style={{ cursor: 'pointer' }} onClick={(e) => e.stopPropagation()}>{courseName}</div>
            </div>
            <div className='d-flex align-items-center gap-1'>
                {timeplay && (
                    <div>
                        <Image className='' src={timeplay} />
                    </div>
                )}
                <div className='count'>
                    {time}
                </div>
            </div>
        </div>
    );
}

export default Lecture;
