import { GetApiData } from "../../utils/http-client";

export const GetAllCoursesForUser = function (params) {
    return GetApiData(`/course/getallcourses?${params}`, 'GET', null, true);
}

export const getUserStats = function () {
    return GetApiData(`/user/stats`, 'GET', null, true);
}

export const getInProgressCourses = function () {
    return GetApiData(`/active/inprogress`, 'GET', null, true);
}

export const getRecommendedCourses = function () {
    return GetApiData(`/course/getCoursesForYou`, 'GET', null, true);
}

export const activateCourse = function (id) {
    return GetApiData(`/active/activate/${id}`, 'POST', null, true);
}

export const getCompletedCourses = function () {
    return GetApiData(`/active/completedcourse`, 'GET', null, true);
}

export const getCertficate = function (id) {
    return GetApiData(`/certificate/${id}`, 'GET', null, true);
}
