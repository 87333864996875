import React from 'react';
import { Form, Image } from 'react-bootstrap';
import search from '../../../assests/search.svg';

const SearchCourse = ({ searchValue, onChange }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Form action="" onSubmit={handleSubmit}>
                <div className="search-input-group input-group border rounded-pill p-1 d-flex align-items-center gap-2 px-3">
                    <div className="input-group-prepend border-0">
                        <div><Image className='' src={search} alt="Search icon" /></div>
                    </div>
                    <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 px-0 search-input"
                        value={searchValue}
                        onChange={onChange}
                    />
                </div>
            </Form>
        </div>
    );
};

export default SearchCourse;
