import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ show, onHide, onDelete, targetType }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className='feedback-header'>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this {targetType}?
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button
                    onClick={onDelete}
                    className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
