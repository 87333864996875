const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export function AuthHeader() {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    return (loggedInUser && loggedInUser.token) ? { "x-access-token": loggedInUser.token } : {}
}
export const GetUserRoles = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    return loggedInUser?.roles || [];
};

export const AuthVerify = () => {
    const loggedInUser = JSON.parse(localStorage?.getItem("user"));
    if (loggedInUser && loggedInUser?.token) {
        const decodedJwt = parseJwt(loggedInUser?.token);
        return decodedJwt.exp * 1000 < Date.now() ? false : true;
    } else return false;
}
// export const GetUserName = () => {
//     const loggedInUser = JSON.parse(localStorage.getItem("user"));

//     if (loggedInUser?.firstname) {
//         return loggedInUser?.firstname;
//     } else return null;
// };


const getInitials = (firstName, lastName) => {
    if (!firstName) return '';
    if (!lastName) return firstName.substring(0, 2).toUpperCase();
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};

// Function to get user's first name and last name from localStorage
export const GetUserName = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    if (loggedInUser?.firstname) {
        const initials = getInitials(loggedInUser.firstname, loggedInUser.lastname);
        return initials;
    } else return null;
};