import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Cognity from '../assests/cognity.png'
import Hero3 from '../assests/hero3.png'
import Hero4 from '../assests/hero4.svg'
import Hero5 from '../assests/hero5.svg'
import Hero6 from '../assests/hero6.svg'
import hiw1 from '../assests/images/signUp.svg'
import hiw2 from '../assests/images/select-course.svg'
import hiw3 from '../assests/images/start-learning.svg'
import hiw4 from '../assests/images/track-progress.svg'
import cta from '../assests/images/call-to-action.jpg'
import techView from '../assests/images/technology-overview.jpg'
// import video from '../assests/vedio/video.mp4'
// import button_icon from '../assests/Arrow 2.svg'
import SideBySideColumn from '../components/sharedComponent/SideBySideColumn'
import CaresoulReview from '../components/CaresoulSection/CaresoulReview'
import { AISVG, AnalyicsSVG, ArrowSVG, CertifiedSVG, GamificationSVG, TailoredSVG, UniversitySVG } from '../assests/SVG'
import { useNavigate } from 'react-router-dom'
const Mainpage = () => {
  const navigate = useNavigate()
  return (
    <div className='body-bg'>
      <section className="bgimage">
        <div className="hero px-2 px-lg-5">
          <Col xl={6} lg={11} md={9}>
            <div className='text-start container-fluid px-0 px-lg-5'>
              <h1 className='poppins-bold lh-2 font-72'>Enhance Your Health and Fitness Skills
              </h1>
              <Col xl={9} lg={10} md={8}>
                <p className='pt-2 poppins-regular lh-lg-sm lh-1 font-24'> Utilizing Cutting-Edge Methods to Provide Personalized, Effective, and Enjoyable Courses.
                </p>
              </Col>

            </div>

            <div className='text-start px-0 px-lg-5'>
              <Button onClick={() => navigate("/login")} variant="primary" type="submit" className='primary-bg login-button px-4 py-3 font-14 mt-2'>
                Get Started
              </Button>
            </div></Col>
        </div>
      </section>
      <Container className='pt-5'>
        <div className='text-center'>
          <Row className='justify-content-center'>
            <Col md={10} className='py-2'>
              <h2 className='dark-color'>
                What is <span className='secondary-color'>MYOFLOW?</span>
              </h2>
              <p className='para-color pt-2 font-24'>
                At MyoFlow, we believe in the power of education to transform lives. Our platform is designed to offer you the best online courses focused on health and wellness, helping you achieve your fitness goals and enhance your overall well-being.
              </p>
            </Col>
            <div className='mt-0 mt-lg-4 cognite-img-div'>
              <Image src={Cognity} className='Cognite-img img-fluid' height={"100%"} width={"100%"} alt='cognity' />
            </div>
          </Row>
        </div>
      </Container>



      <Container fluid className='pt-lg-5 px-2 px-lg-5'>
        <section className='px-0 px-lg-5'>
          <SideBySideColumn
            imageSrc={Hero3}
            heading="<span className='dark-color'>Everything you can do in a physical classroom, <span className='secondary-color'>you can do with MYOFLOW</span></span>"
            text="MYOFLOW’s school management software helps traditional and online schools manage scheduling, attendance, payments, and virtual classrooms all in one secure cloud-based system."
          // rowReverse={true}
          />
          <section className='py-lg-5'>
            <div className='text-center'>
              <div className='justify-content-center'>
                <Col md={8} className='my-2 mx-auto'>
                  <h2 className='dark-color'>
                    What <span className='secondary-color'>We Offer?</span>
                  </h2>
                  <p className='para-color pt-2 font-24'>
                    Enhance your skills with our comprehensive services, designed to propel your future forward.</p>
                </Col>
              </div>
            </div>
          </section>
          <SideBySideColumn
            imageSrc={Hero4}
            heading="<span className='dark-color'>Free <span className='secondary-color'>Online</span> Certification.</span>"
            text="We provide certificates of completion or achievement that participants can add to their resumes or LinkedIn profiles. This can motivate learners to complete courses and provide tangible proof of their efforts and skills.
"
            rowReverse={true}

          />
          <SideBySideColumn
            imageSrc={Hero5}
            heading="<span className='secondary-color'>Free <span className='dark-color '>Online Courses</span></span>"
            text="  Our classes offer a dynamic set of teaching tools designed to be deployed and used during each session. Teachers can hand out assignments in real-time for students to complete and submit."
          // rowReverse={true}
          />
          <SideBySideColumn
            imageSrc={Hero6}
            heading="<span className='dark-color'>Assessments, <span className='secondary-color'>Quizzes, </span>Tests,</span>"
            text="We provide certificates of completion or achievement that participants can add to their resumes or LinkedIn profiles. This can motivate learners to complete courses and provide tangible proof of their efforts and skills."
            rowReverse={true}
          />

          {/* feature sectons  */}
          <Container fluid className="pb-lg-5 py-3 feature-section">
            <h3 className="text-center ">
              Our  <span className='secondary-color'>Features </span>
            </h3>
            <Row className="justify-content-center mt-md-5 mt-3">
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <AISVG />
                </div>


                <h5 className="fw-semibold mt-2">AI-driven Training</h5>
                <p className="small">Harness the power of AI to create adaptive learning paths tailored to each individual's needs.</p>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <GamificationSVG />
                </div>


                <h5 className="fw-semibold mt-2">Gamification</h5>
                <p className="small">Engage learners with game-like elements to enhance motivation and retention in exercises.</p>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <UniversitySVG />
                </div>


                <h5 className="fw-semibold mt-2">Access to Comprehensive Courses</h5>
                <p className="small">Unlock a library of accredited courses in health, fitness, and wellness for your personal development.</p>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <TailoredSVG />
                </div>


                <h5 className="fw-semibold mt-2">Tailored Training Solutions</h5>
                <p className="small">Customized training solutions designed to meet your specific fitness and wellness needs.</p>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <CertifiedSVG />
                </div>


                <h5 className="fw-semibold mt-2">Certified Courses</h5>
                <p className="small">All courses are certified by renowned external authorities, ensuring quality and recognition.</p>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-md-3 mb-3 px-xxl-5 px-lg-4 text-center">
                <div style={{ height: "120px" }}>

                  <AnalyicsSVG />
                </div>


                <h5 className="fw-semibold mt-2">Analytics</h5>
                <p className="small">Track and measure your progress with detailed analytics and reporting tools.</p>
              </Col>
            </Row>
          </Container>


          {/* How it works section */}


          <Container fluid className="pb-lg-5 py-3">
            <h3 className="text-center">
              How <span className="secondary-color">It Works</span>
            </h3>

            <Row className="align-items-center mt-md-5 mt-3">
              <Col xs={12} md={6} className="mb-4 d-flex align-items-center">
                <Image src={hiw1} alt="Sign Up Icon" className="img-fluid" style={{ maxHeight: '300px', width: "100%" }} />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <h5>Step 1: Sign Up</h5>
                <p>Create your account and set up your training platform in minutes.</p>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs={12} md={6} className="mb-4 d-flex align-items-center order-md-2">
                <Image src={hiw2} alt="Select Courses Icon" className="img-fluid" style={{ maxHeight: '300px', width: "100%" }} />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <h5>Step 2: Select Courses</h5>
                <p>Choose from a wide range of courses tailored to your industry's needs.</p>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs={12} md={6} className="mb-4 d-flex align-items-center">
                <Image src={hiw3} alt="Start Learning Icon" className="img-fluid" style={{ maxHeight: '300px', width: "100%" }} />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <h5>Step 3: Start Learning</h5>
                <p>Begin your learning journey with our engaging and interactive modules.</p>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs={12} md={6} className="mb-4 d-flex align-items-center order-md-2">
                <Image src={hiw4} alt="Track Progress Icon" className="img-fluid" style={{ maxHeight: '300px', width: "100%" }} />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <h5>Step 4: Track Progress</h5>
                <p>Monitor your progress and performance with real-time analytics.</p>
              </Col>
            </Row>
          </Container>
          <Container fluid className='pb-lg-5  py-3'>
            <Row className={`justify-content-between align-items-center pb-lg-5`}>
              <Col xl={5} md={11}>
                <div>
                  <Col xl={10}>
                    <div className='d-flex align-items-center gap-4 mb-3'>
                      <div>

                        <div className='hr-line'></div>
                      </div>
                      <p className='text-uppercase mb-0 dark-color testi'>Testimonial</p>
                    </div>
                    <div className='pt-3 pb-1'>
                      <h2 className='dark-color testi-head'>What They Say?</h2>
                    </div>
                    <div>
                      <p className='para-color pt-2 font-24'>
                        MYOFLOW has got more than 100k positive ratings from our users around the world.
                      </p>
                      <p className='para-color pt-2 font-24'>
                        Some of the students and teachers were greatly helped by the Asynch Learning.
                      </p>
                      <p className='para-color pt-2 font-24'>
                        Are you too? Please give your assessment                                            </p>
                    </div>
                    <div className='pt-2 py-3'>
                      <Button className='d-flex align-items-center gap-4 p-0 asess-btn'>
                        <div className='py-3 ps-4 poppins-semibold assesment-text'>Write your assesment</div>
                        <div className='assesment-bg py-3 px-3'>
                          {/* <Image className='' src={button_icon} /> */}
                          <ArrowSVG />
                        </div>
                      </Button>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col xl={6} md={8}>
                <CaresoulReview />
              </Col>
            </Row>
          </Container>
          <Container fluid className="py-5">
            <Row className="align-items-center">
              <Col md={6} className="text-center">
                <Image
                  src={techView}
                  alt="Technology Overview"
                  fluid
                  className="img-fluid rounded-4 border"
                  style={{ maxHeight: '500px' }}
                />
              </Col>
              <Col md={6} className="text-center text-md-start mb-2 mt-md-0 mt-3 mb-md-0">
                <h2>Technology Overview</h2>
                <p className="lead">
                  Our platform integrates advanced AI and machine learning technologies to deliver personalized training experiences that adapt to each learner's progress and preferences.
                </p>
                <h3>Security</h3>
                <p>
                  We prioritize your data security with robust measures and comply with the highest standards of privacy protection.
                </p>
              </Col>

              {/* Image or Visual */}

            </Row>
          </Container>
          <Container fluid className="my-5 py-5 bg-light ">
            <Row className="align-items-center">
              {/* Content Column */}
              <Col xs={12} md={6} className="text-center ps-3 text-md-start mb-4 mb-md-0">
                <h2 className="fw-semibold">Ready to Transform Your Workforce?</h2>
                <p className="lead mb-4">Join hundreds of companies in revolutionizing training.</p>
                <Button onClick={() => navigate("/login")} variant="primary" type="submit" className='primary-bg login-button px-4 py-3 font-14 mt-2'>Get Started Today</Button>
              </Col>
              {/* Image Column */}
              <Col xs={12} md={6} className="text-center">
                <Image src={cta} alt="Transform Your Workforce" className='rounded-4' fluid />
              </Col>
            </Row>
          </Container>

        </section>
      </Container>
    </div>
  )
}

export default Mainpage
