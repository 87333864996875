import React from 'react';
import { Form } from 'react-bootstrap';

const SortingBy = ({ sortValue, onChange }) => {
    const handleSelectChange = (e) => {
        onChange(e); // Pass the event object to parent handler
    };

    return (
        <div>
            <Form.Group controlId="formSortBy" className='d-flex align-items-center justify-content-end gap-3 select-form'>
                <Form.Label className='font-14 mb-0 sort-heading text-color-light'>Sort By Date:</Form.Label>
                <Form.Select aria-label="Sort By Select" className='w-auto selection' value={sortValue} onChange={handleSelectChange}>
                    <option value="asc" className='text-capitalize'>Ascending</option>
                    <option value="desc">Descending</option>
                    {/* <option value="design">Design</option>
                    <option value="marketing">Marketing</option> */}
                </Form.Select>
            </Form.Group>
        </div>
    );
};

export default SortingBy;
