import React from 'react';
import { Card, Container, Image, Row } from 'react-bootstrap';
import moment from 'moment';
const CourseReviews = ({ reviews }) => {
    console.log("Reviews", reviews)
    return (
        <Container className="mt-4">
            <h2 className="mb-4">Reviews</h2>
            <Row>
                {reviews?.map((review, index) => (
                    <React.Fragment key={review.id}>
                        <Card className='border-0 p-0'>
                            <Card.Body className="d-flex gap-2 px-0">
                                <Image
                                    src={review?.userId?.imageUrl ? review?.userId?.imageUrl : `https://api.dicebear.com/7.x/initials/svg?seed=${review?.userId?.firstname}&radius=50`}
                                    className="rounded-circle"
                                    style={{ width: '40px', height: '40px' }}
                                    alt={review.name}
                                />
                                <div>
                                    <Card.Title className='mb-0' style={{ fontSize: "16px" }}>{review?.userId?.firstname}</Card.Title>
                                    <Card.Text className='text-muted mb-0' style={{ fontSize: "14px" }}>{review?.feedback}</Card.Text>
                                    <Card.Text className='text-muted' style={{ fontSize: "10px" }}>
                                        {moment(review.createdAt).format('MMMM Do YYYY')}
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                        {index < reviews.length - 1 && <hr className='mb-0' />}
                    </React.Fragment>
                ))}
            </Row>
        </Container>
    );
}

export default CourseReviews;
