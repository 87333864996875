import React, { useState } from 'react';
import fileIcon from '../../assests/FileText.svg';
import { Button, Image } from 'react-bootstrap';
import CourseContent from '../sharedComponent/CourseContent';
import CourseHeading from '../sharedComponent/CourseHeading';

const AttachFile = ({ lecture }) => {
    const [loading, setLoading] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(null);

    const downloadFile = async (fileUrl, fileName) => {
        setLoading(true);
        setDownloadingFile(fileName);
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // Use the file name provided
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('File download failed', error);
        }
        setLoading(false);
        setDownloadingFile(null);
    };

    return (
        <div className='py-4'>
            <div>
                <CourseHeading heading={"Attached Files"} />
            </div>
            {lecture?.file && lecture.file.length > 0 ? (
                lecture.file.map((fileUrl, index) => (
                    <div key={index} className='mx-0 content-bg py-lg-3 py-2 px-lg-4 px-2 d-flex flex-lg-row flex-column align-items-lg-center justify-content-between gap-lg-0 gap-3 mt-3'>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <Image className='' src={fileIcon} />
                                </div>
                                <div>
                                    <p className='mb-0 poppins-medium'>File {index + 1}</p>
                                </div>
                            </div>
                        </div>
                        <div className='ms-auto'>
                            <Button
                                className='d-flex align-items-center gap-4 login-button primary-bg download-btn-text'
                                onClick={() => downloadFile(fileUrl, `File_${index + 1}.pdf`)}
                                disabled={loading && downloadingFile === `File_${index + 1}.pdf`}
                            >
                                <div className='poppins-semibold start-btn-text'>
                                    {loading && downloadingFile === `File_${index + 1}.pdf` ? 'Downloading...' : 'Download File'}
                                </div>
                            </Button>
                        </div>
                    </div>
                ))
            ) : (
                <div className='pt-2'>
                    <CourseContent text={'Attach file is not available'} />
                </div>
            )}
        </div>
    );
}

export default AttachFile;
