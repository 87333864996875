import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Sidebar from '../sharedComponent/Sidebar';
import Footer from '../sharedComponent/Footer';
import SidebarHeader from '../sharedComponent/SidebarHeader';
import { Container } from 'react-bootstrap';
import { GetUserRoles } from '../../utils/auth.utils';
const SidebarLayout = () => {
    const userRoles = GetUserRoles();
    const [isOpen, setIsOpen] = useState(true);
    const toggleSidebar = () => setIsOpen(!isOpen);
    return (
        <div>
            <SidebarHeader toggleSidebar={toggleSidebar} isOpen={isOpen} />
            <div className="d-flex">
                <Sidebar isOpen={isOpen} userRoles={userRoles} />
                <main className='main-dislay'>
                    <Container fluid className='m-auto px-3 px-lg-5'>
                        <div className=''>
                            <Outlet />
                        </div>
                    </Container>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default SidebarLayout
