import { GetApiData } from "../../utils/http-client";

export const LoginUser = function (data) {
    return GetApiData(`/user/login`, 'POST', data, true);
}

export const ForgotPassword = function (data) {
    return GetApiData(`/user/forget-password`, 'POST', data, true);
}
export const VerificationCode = function (data) {
    return GetApiData(`/user/verify-otp`, 'POST', data, true);
}
export const ResetUserPassword = function (data) {
    return GetApiData(`/user/reset-password`, 'POST', data, true);
}