import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import Mainpage from "./pages/Mainpage";
import LMS from "./pages/LMS";
import Layout from "./components/Layout/Layout";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import SidebarLayout from "./components/Layout/SidebarLayout";
import Dashboard from "./pages/Dashboard";
import AllCourses from "./pages/AllCourses";
import CourseDetail from "./pages/CourseDetail";
import CourseContent from "./pages/CourseContent";
import Profile from "./pages/Profile";
import CompletedCourse from "./pages/CompletedCourse";
import HelpSupport from "./pages/HelpSupport";
import ResetPassword from "./pages/ResetPassword";
import { GetUserRoles } from "./utils/auth.utils";
import TeacherDashboard from "./pages/TeacherDashboard/TeacherDashboard";
import CreateCourse from "./pages/TeacherDashboard/CreateCourse";
import MyCourses from "./pages/TeacherDashboard/MyCourses";
import Solutions from "./pages/Solutions";
import Blogs from "./pages/Blogs";
import Blogsdetail from "./pages/BlogsDetails";

const Routesof = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true)
    const location = useLocation();

    useEffect(() => {
        const userRoles = GetUserRoles();
        setRoles(userRoles);
        setLoading(false);
    }, [location.pathname]);

    const hasRole = (role) => roles.includes(role);

    return (

        <Routes>
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route element={<Layout />}>
                <Route index element={<Mainpage />} />
                <Route path="/lms" element={<LMS />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogdetails/:id" element={<Blogsdetail />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
            </Route>

            {hasRole("user") && (
                <Route element={<SidebarLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/all-courses" element={<AllCourses />} />
                    <Route path="/all-courses/course-detail" element={<CourseDetail />} />
                    <Route path="/all-courses/course-detail/content/:id" element={<CourseContent />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/completed-course" element={<CompletedCourse />} />
                    <Route path="/help" element={<HelpSupport />} />
                </Route>
            )}

            {hasRole("admin") && (
                <Route element={<SidebarLayout />}>
                    <Route path="/dashboard" element={<TeacherDashboard />} />
                    <Route path="/all-courses" element={<MyCourses />} />
                    <Route path="/all-courses/course-detail" element={<CourseDetail roles={roles} />} />
                    <Route path="/all-courses/course-detail/content/:id" element={<CourseContent roles={roles} />} />
                    <Route path="/all-courses/edit-course" element={<CreateCourse />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/create-course" element={<CreateCourse />} />
                    <Route path="/help" element={<HelpSupport />} />
                </Route>
            )}
            {!loading &&
                <Route path="*" element={<NotFound />} />
            }
        </Routes>
    );
};

export default Routesof;
