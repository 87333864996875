import { GetApiData } from "../../utils/http-client";

export const GetDashbaord = function () {
    return GetApiData(`/active/adminStats`, 'GET', null, true);
}
export const GetTimestamp = function () {
    return GetApiData(`/timestamp/`, 'GET', null, true);
}
export const GetChartData = function () {
    return GetApiData(`/getChartData/`, 'GET', null, true);
}
export const uploadUserFile = function (data) {
    return GetApiData(`/upload/multiple`, 'POST', data, null, true);
}
export const uploadVideo = function (data) {
    return GetApiData(`/upload/video`, 'POST', data, null, true);
}