import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Col } from 'react-bootstrap';

const TinyEditor = ({ setDescription, value }) => {
  const [loading, setLoading] = useState(true);

  const handleEditorChange = (content) => {
    setDescription(content);
  };

  useEffect(() => {
    if (value !== undefined) {
      setLoading(false);
    }
  }, [value]);

  return (
    <>
      <Col xs={12} lg={12} className='my-3'>
        {loading && (
          <Col lg={12} className="text-center">
            <></>
          </Col>
        )}
        <Editor
          apiKey='ra7rzeo2ix1xuw3tc0ld7es7un8rwxuri7o3eyrns6pcbbbo'
          onInit={() => {
            setLoading(false);
          }}
          value={value || ""}
          onEditorChange={handleEditorChange}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: '| blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist | ',
            content_style: 'body { font-family:Poppins; font-size:14px }'
          }}
        />
      </Col>
    </>
  );
};

export default TinyEditor;
