import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import { Categories } from '../../services/createCourse/createCourse.service';

const BasicInformation = forwardRef(({ formData, setFormData }, ref) => {
    const { duration, level, language, subcategoryId, categoryId, subTitle, title, topic } = formData;
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [titleError, setTitleError] = useState('');
    const [topicError, setTopicError] = useState('');
    const [subtitleError, setSubtitleError] = useState('');
    const [categoryIdError, setCategoryIdError] = useState('');
    // const [subcategoryIdError, setSubcategoryIdError] = useState('');
    const [languageError, setLanguageError] = useState('');
    // const [subtitleLangError, setSubtitleLangError] = useState('');
    const [levelError, setLevelError] = useState('');
    const [durationError, setDurationError] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await Categories();
                setCategories(response.data.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategories([]);
            }
        };
        fetchCategories();
    }, []);

    const handleTopicChange = (event) => {
        setFormData({ ...formData, topic: event.target.value });
        setTopicError('');
    };

    const handleTitleChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
        setTitleError('');
    };

    const handleSubtitleChange = (event) => {
        setFormData({ ...formData, subTitle: event.target.value });
        setSubtitleError('');
    };

    const handleCategoryChange = (event) => {
        const selectedCategoryId = event.target.value;
        setFormData({ ...formData, categoryId: selectedCategoryId });
        setCategoryIdError('');
        const selectedCategory = categories.find(category => category._id === selectedCategoryId);
        setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    };

    const handleSubcategoryChange = (event) => {
        setFormData({ ...formData, subcategoryId: event.target.value });
        // setSubcategoryIdError('');
    };

    const handleLanguageChange = (event) => {
        setFormData({ ...formData, language: event.target.value });
        setLanguageError('');
    };

    // const handleSubtitleLangChange = (event) => {
    //     setFormData({ ...formData, subtitleLang: event.target.value });
    //     setSubtitleLangError('');
    // };

    const handleLevelChange = (event) => {
        setFormData({ ...formData, level: event.target.value });
        setLevelError('');
    };

    const handleDurationChange = (event) => {
        setFormData({ ...formData, duration: event.target.value });
        setDurationError('');
    };

    const validateBasicInformation = () => {
        let isValid = true;

        if (!formData.topic) {
            setTopicError('Topic is required');
            isValid = false;
        } else {
            setTopicError('');
        }

        if (!formData.title) {
            setTitleError('Title is required');
            isValid = false;
        } else {
            setTitleError('');
        }

        if (!formData.subTitle) {
            setSubtitleError('Subtitle is required');
            isValid = false;
        } else {
            setSubtitleError('');
        }

        if (!formData.categoryId) {
            setCategoryIdError('Category is required');
            isValid = false;
        } else {
            setCategoryIdError('');
        }

        if (formData.subcategoryId === '') {
            //     setSubcategoryIdError('Sub-category is required');
            //     isValid = false;
            // } else {
            //     setSubcategoryIdError('');
            setFormData({ ...formData, subcategoryId: null });
        }

        // if (!formData.language) {
        //     setLanguageError('Language is required');
        //     isValid = false;
        // } else {
        //     setLanguageError('');
        // }

        // if (!formData.subtitleLang) {
        //     setSubtitleLangError('Subtitle language is required');
        //     isValid = false;
        // } else {
        //     setSubtitleLangError('');
        // }

        if (!formData.level) {
            setLevelError('Level is required');
            isValid = false;
        } else {
            setLevelError('');
        }

        if (!formData.duration) {
            setDurationError('Duration is required');
            isValid = false;
        } else {
            setDurationError('');
        }

        return isValid;
    };

    useImperativeHandle(ref, () => ({
        validate: validateBasicInformation
    }));

    return (
        <div>
            <div className='py-2 border-up'>
                <div className='d-flex align-items-md-center justify-content-between mb-lg-1 py-md-4 py-2 px-md-4 border-down flex-md-row flex-column'>
                    <div>
                        <h3 className='course-Subheading-small poppins-semibold mb-0'>Basic Information</h3>
                    </div>
                </div>
            </div>
            <div className='pt-3'>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formTitle" className='pb-3'>
                                <FormLabel className='font-14'>Title</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Title"
                                        value={title}
                                        onChange={handleTitleChange}
                                        maxLength={80}
                                        className='input-field'
                                    />
                                    <div className="char-count">{title?.length || 0}/80</div>
                                </div>
                                <Form.Text className="text-danger">{titleError}</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="formSubtitle" className='pb-3'>
                                <FormLabel className='font-14' >Subtitle</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Subtitle"
                                        value={subTitle}
                                        onChange={handleSubtitleChange}
                                        maxLength={120}
                                        className='input-field'
                                    />
                                    <div className="char-count">{subTitle?.length || 0}/120</div>
                                </div>
                                <Form.Text className="text-danger">{subtitleError}</Form.Text>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="formCategory" className='pb-3'>
                                <FormLabel className='font-14' >Course Category</FormLabel>
                                <Form.Select className='input-field' value={categoryId} onChange={handleCategoryChange}>
                                    <option value=''>Select category...</option>
                                    {categories.map(category => (
                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </Form.Select>
                                <Form.Text className="text-danger">{categoryIdError}</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formSubCategory" className='pb-4'>
                                <FormLabel className='font-14' >Course Sub-category</FormLabel>
                                <Form.Select className='input-field' value={subcategoryId} onChange={handleSubcategoryChange}>
                                    <option value=''>Select sub-category...</option>
                                    {subcategories.map(subcategory => (
                                        <option key={subcategory._id} value={subcategory._id}>{subcategory.name}</option>
                                    ))}
                                </Form.Select>
                                {/* <Form.Text className="text-danger">{subcategoryIdError}</Form.Text> */}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="formSubtitle" className='pb-3'>
                                <FormLabel className='font-14' >Course Topic</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter topic"
                                        value={topic}
                                        onChange={handleTopicChange}
                                        className='input-field'
                                    />
                                </div>
                                <Form.Text className="text-danger">{topicError}</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="formProgrammingLanguage" className='pb-3'>
                                <FormLabel className='font-14'>Programming Language (Optional)</FormLabel>
                                <Form.Select className='input-field' value={language} onChange={handleLanguageChange} >
                                    <option value=''>Select language...</option>
                                    <option>React</option>
                                    <option>Python</option>
                                    <option>Javascript</option>
                                    <option>Java</option>
                                    <option>C#</option>
                                    <option>Ruby</option>
                                    <option>PHP</option>
                                    <option>Swift</option>
                                    <option>Go</option>
                                    <option>Kotlin</option>
                                    <option>Typescript</option>
                                </Form.Select>
                                <Form.Text className="text-danger">{languageError}</Form.Text>
                            </Form.Group>
                        </Col>
                        {/* <Col md={3}>
                            <Form.Group controlId="formSubtitleLanguage" className='pb-3'>
                                <FormLabel className='font-14'>Subtitle Language</FormLabel>
                                <Form.Select className='input-field' value={subtitleLang} onChange={handleSubtitleLangChange} >
                                    <option value=''>Select subtitle language...</option>
                                    <option>English</option>
                                    <option>Spanish</option>
                                    <option>French</option>
                                    <option>German</option>
                                    <option>Dutch</option>
                                    <option>Hindi</option>
                                    <option>Chinese</option>
                                    <option>Korean</option>
                                    <option>Arabic</option>
                                    <option>Russian</option>
                                </Form.Select>
                                <Form.Text className="text-danger">{subtitleLangError}</Form.Text>
                            </Form.Group>
                        </Col> */}
                        <Col md={3}>
                            <Form.Group controlId="formCourseLevel" className='pb-3'>
                                <FormLabel className='font-14'>Course Level</FormLabel>
                                <Form.Select className='input-field' value={level} onChange={handleLevelChange} >
                                    <option value=''>Select level...</option>
                                    <option>beginner</option>
                                    <option>intermediate</option>
                                    <option>expert</option>
                                </Form.Select>
                                <Form.Text className="text-danger">{levelError}</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="formDuration" className='pb-3'>
                                <FormLabel className='font-14' >Duration</FormLabel>
                                <Form.Select className='input-field' value={duration} onChange={handleDurationChange} >
                                    <option value=''>Select duration...</option>
                                    <option>6-12 Months</option>
                                    <option>3-6 Months</option>
                                    <option>1-3 Months</option>
                                    <option>1-4 Weeks</option>
                                    <option>1-7 Days</option>
                                </Form.Select>
                                <Form.Text className="text-danger">{durationError}</Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
});

export default BasicInformation;
