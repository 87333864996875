import { GetApiData } from "../../utils/http-client";
export const GetLectureById = function (id) {
    return GetApiData(`/lecture/getLectureById/${id}`, 'GET', null, true);
}

export const GetLectureForUser = function (id) {
    return GetApiData(`/active/getCourseWithSections/${id}`, 'GET', null, true);
}

export const updatedProgress = function (data) {
    return GetApiData(`/active/completeSectionAndUpdateProgress`, 'PUT',data, null, true);
}