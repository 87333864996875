import React from 'react'
import { Container } from 'react-bootstrap'
import TermsHeadingText from '../components/sharedComponent/TermsHeadingText'

const Terms = () => {
    const termsData = [
        {
            heading: 'Acceptance of Terms:',
            text: 'By registering for our courses, you accept and agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use our services.',
        },
        {
            heading: 'Registration and Account Security:',
            text: 'Users are required to register in order to access our courses. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.',
        },
        {
            heading: 'Course Enrollment:',
            text: 'Enrollment in our courses is subject to availability. We reserve the right to accept or reject any registration to any course without obligation to explain our decision.',
        },
        {
            heading: 'Content Ownership and Copyright:',
            text: 'All materials, including videos, texts, graphics, and images provided in our courses are owned by MYOFLOW or our content providers and are protected by intellectual property laws. You may not redistribute, sell, or reproduce any course materials without our express written consent.',
        },
        {
            heading: 'User Conduct:',
            text: 'Users are expected to use our platform in a respectful and ethical manner. Any form of harassment, cheating, or violation of applicable laws is strictly prohibited.',
        },
        {
            heading: 'Fees and Payments:',
            text: 'Certain aspects of our services may require payment and are non-refundable except in specific circumstances as outlined in our refund policy.',
        },
        {
            heading: 'Refund Policy:',
            text: 'Our refund policy is detailed separately and outlines the conditions under which refunds for payments made to us may be claimed.',
        },
        {
            heading: 'Privacy Policy:',
            text: 'Your privacy is important to us. Our privacy policy details how we collect, use, and protect your personal information. Please review it to understand our practices.',
        },
        {
            heading: 'Changes to Terms and Conditions:',
            text: 'We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on this site. Your continued use of our services after any changes indicates your acceptance of the new terms.',
        },
        {
            heading: 'Termination:',
            text: 'We may terminate your access to all or any part of our services at any time, with or without cause, with or without notice, effective immediately.',
        },
        {
            heading: 'Disclaimer of Warranties:',
            text: 'Our services are provided on an "as is" basis without warranties of any kind, either express or implied. MYOFLOW disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.',
        },
        {
            heading: 'Limitation of Liability:',
            text: 'MYOFLOW will not be liable for any indirect, incidental, special, consequential or punitive damages resulting from the use of or inability to use our services.',
        },
        {
            heading: 'Governing Law:',
            text: 'These terms and conditions are governed by the laws of [Your Jurisdiction] without regard to its conflict of law provisions.',
        },
    ];
    return (
        <Container fluid className='pt-lg-5 pt-3 px-2 px-xl-5 py-5'>
            <div className='px-2 px-xl-5'>
                <div className='px-lg-5'>
                    <div className='px-lg-5'>
                        <div className='pb-4 term-border'>
                            <h2 className='poppins-bold '>Terms & Conditions</h2>
                        </div>
                        <div className='pt-lg-4'>
                            {termsData.map((term, index) => (
                                <TermsHeadingText key={index} heading={term.heading} text={term.text} />
                            ))}                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Terms
