import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { uploadVideo } from '../../services/TeacherDashboard/Dashboard.service';

const VideoUploadModal = ({ show, onHide, onSave }) => {
    const [video, setVideo] = useState(null);
    const [duration, setDuration] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [videoName, setVideoName] = useState('');

    const handleSave = () => {
        if (video) {
            // const formData = new FormData();
            // console.log("formdata before", formData)
            // formData.append('video', video);
            onSave(video, duration);
            console.log("video", video)
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const toastId = toast.loading("Uploading", { closeButton: true });
        const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
        e.target.value = null;
    
        if (file) {
            const fileSizeLimit = 400 * 1024 * 1024; 
            if (file.size > fileSizeLimit) {
                toast.update(toastId, {
                    render: "File size limit exceeded (400MB)",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setIsLoading(false);
                return; 
            }
    
            try {
                const formData = new FormData();
                formData.append('attachments', file);
                setVideo(formData);
                const response = await uploadVideo(formData);
                console.log("response", response?.data?.data[0]?.url);
                const newFileSrc = response?.data?.data[0]?.url;
                setDuration(response?.data?.data[0]?.duration);
                setVideo(newFileSrc);
                setVideoUrl(URL.createObjectURL(file));
                setVideoName(file.name);
                console.log("video", videoName);
                toast.dismiss(toastId);
                setIsLoading(false);
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setIsLoading(false);
            }
        }
    };
    

    return (
        <Modal show={show} onHide={onHide} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='feedback-header'>Upload Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!videoUrl ? (
                    <>
                        <Form.Group controlId="formBasicPassword" className="pb-3" style={{ cursor: 'pointer' }}>
                            <InputGroup className="mb-3">
                                <input
                                    className='input-field'
                                    type="file"
                                    accept="video/mp4, video/quicktime, video/x-msvideo"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none', cursor: 'pointer' }}
                                    id="fileInputVideo"
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Upload Files"
                                    readOnly
                                    onClick={() => document.getElementById('fileInputVideo').click()}
                                />
                                <InputGroup.Text onClick={() => document.getElementById('fileInputVideo').click()}>
                                    Upload File
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {/* </Form.Group> */}
                        <small className="text-muted">
                            Note: All files should be at least 720p and less than 4.0 GB.
                        </small>
                    </>
                ) : (
                    <Row>
                        <Col md="6">
                            <div className='min-img-size-upload-video'>
                                <video
                                    controls={false}
                                    src={video}
                                />
                            </div>
                        </Col>
                        <Col md="6" className="d-flex flex-column align-items-start">
                            <div className="text-success">File uploaded successfully.</div>
                            <div>{videoName}</div>
                            <button
                                variant="link"
                                className="p-0 primary-color bg-white border-0 text-decoration-none pt-2"
                                onClick={() => {
                                    setVideo(null);
                                    setVideoUrl(null);
                                    setVideoName('');
                                }}
                            >
                                Replace Video
                            </button>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={onHide} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={isLoading || !video}
                    className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                >
                    Upload Video
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VideoUploadModal;
