import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Image, FormLabel, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assests/logo.png'
import { AuthVerify } from '../utils/auth.utils';
import { toast } from 'react-toastify';
import { BiHide, BiShow } from 'react-icons/bi';
// import { FcGoogle } from 'react-icons/fc';
import { LoginUser } from '../services/login/login.service';

const Login = () => {
    const verify = AuthVerify();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    // const [rememberMe, setRememberMe] = useState(false);
    // const [agree, setAgree] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    // const [agreeError, setAgreeError] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        // Password validation regex
        return password.length >= 8 && password.length <= 20;
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    // const handleAgreeChange = (e) => {
    //     setAgree(e.target.checked)
    //     setAgreeError('');
    // };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setEmailError('');
        setPasswordError('');
        let isValid = true;
        // Validate email
        // if (!agree) {
        //     setAgreeError("Please agree Terms and Privacy policy")
        //     isValid = false;
        // }
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        // Validate password
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters. Must contain at least one uppercase and one special character.');
            isValid = false;
        }
        if (isValid) {
            // console.log("valid")
            setLoading(true);
            try {
                const response = await LoginUser({
                    email: email,
                    password: password,
                });
                const user = response?.data?.data?.newUserInfo;
                console.log("user", user)
                localStorage.setItem("user", JSON.stringify(user));
                navigate(`/dashboard`)
                setLoading(false);
            } catch (error) {
                console.log("login error", error)
                if (error.code === 'ERR_NETWORK') {
                    toast.error("Network Error", {
                        isLoading: false,
                        autoClose: 3000,
                        // theme: "dark",
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } else {

                    toast.error(error?.response?.data?.error, {
                        isLoading: false,
                        autoClose: 3000,
                        // theme: "dark",
                        hideProgressBar: true,
                        closeButton: false,
                    });
                }
                setLoading(false);
                // setError(error.response.data.error);
            }
        }

    };

    useEffect(() => {
        if (verify) {
            navigate(`/dashboard`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, verify]);

    return (
        <div className="login-bg d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col lg={5} md={8}>
                        <div style={{ margin: "auto", width: "200px", marginBottom: "50px" }}>
                            <Link to={"/"}>
                                <Image src={logo} alt="logo" width={200} />
                            </Link>

                        </div>
                        <div className='sign-heading text-center pb-3'>
                            <p className='poppins-semibold font-28 mb-1'>Log In</p>
                            <p className='poppins-regular font-14' style={{ color: '#2D3748' }}>To access the Courses Dashboard</p>
                        </div>
                        <Form>
                            <Form.Group controlId="formBasicEmail" className='pb-4'>
                                <FormLabel className='font-14' for="email">Email</FormLabel>
                                <Form.Control type="email" className='input-field' placeholder="Email" value={email} onChange={handleEmailChange} />
                                <Form.Text className="text-danger">{emailError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword" className="pb-3">
                                <FormLabel className="font-14" htmlFor="newPassword">Password</FormLabel>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        className='input-field'
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        aria-label="New Password"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2" onClick={togglePasswordVisibility}>
                                        {showPassword ? <BiHide /> : <BiShow />}
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text className="text-danger">{passwordError}</Form.Text>
                            </Form.Group>
                            {/* <Row className='justify-content-between pb-3'>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicCheckbox" className='font-14'>
                                        <Form.Check type="checkbox" className='custom-check' label="Remember me" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} className='text-end'>
                                    <Link to={'/forgot-password'} variant="link" className='p-0 font-14 primary-color'>
                                        Forgot Password?
                                    </Link>
                                </Col>
                            </Row> */}
                            <Row className='justify-content-center pb-3'>
                                {/* <Col md={8}>
                                    <Form.Group controlId="formBasic" className='font-14'>
                                        <Form.Check type="checkbox" className='custom-check' label={<span className='font-14'>I agree to all the <Link to={'/terms-and-conditions'} className='primary-color text-decoration-none'>Terms & Conditons</Link></span>} checked={agree} onChange={handleAgreeChange} />
                                        <Form.Text className="text-danger">{agreeError}</Form.Text>
                                    </Form.Group>
                                </Col> */}
                                <Col md={4} className=''>
                                    <Link to={'/forgot-password'} variant="link" className='p-0 font-14 primary-color'>
                                        Forgot Password?
                                    </Link>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit" onClick={handleLogin} className='w-100 primary-bg login-button py-2 font-14' disabled={loading} >
                                {loading ? 'Logging In...' : 'Log In'}
                            </Button>
                            {/* <Button type='submit' className="d-flex align-items-center justify-content-center gap-2 w-100 py-2 font-14 mt-4 sign-in-google-btn" onClick={handleLogin}>
                                <FcGoogle size={16} />
                                Sign-in with google
                            </Button> */}
                            <div className="col-lg-12 new-user text-center mt-5 font-14">
                                <p className="mb-0">Don't have an account?
                                    <Link className="ps-1 text-decoration-none primary-color log-in-link" to={`/sign-up`}>Register</Link>
                                </p>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            {/* Render ForgetPassword modal */}
            {/* <ForgetPassword show={showForgetPasswordModal} onClose={handleCloseForgetPasswordModal} /> */}
        </div>
    );
};

export default Login;
