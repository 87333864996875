import React from 'react';
import { Form } from 'react-bootstrap';

const CustomCheckbox = ({ label, value, checked, onChange }) => {
    const handleChange = (e) => {
        onChange(value, e.target.checked);
    };

    return (
        <Form.Group controlId={value} className='pb-4 px-4 d-flex align-items-center justify-content-between'>
            <Form.Check
                className='custom-check d-flex align-item-center mb-0'
                type="checkbox"
                label={label}
                value={value}
                checked={checked}
                onChange={handleChange}
            />
            {/* <div className='count'>
                123
            </div> */}
        </Form.Group>
    );
};

export default CustomCheckbox;
