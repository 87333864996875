import React from 'react'
import { useNavigate } from 'react-router-dom'
import CourseHeading from '../sharedComponent/CourseHeading'
import { Col, Image, Row } from 'react-bootstrap'
import folder from '../../assests/FolderNotchOpen.svg'
import playicon from '../../assests/PlayCircle.svg'
import clock from '../../assests/Clock.svg'
import Accordian from './Accordian'
import coursePlay from '../../assests/coursePlay.svg'
import Lecture from './Lecture'

const Curriculum = ({ sections }) => {
    const navigate = useNavigate();
    console.log("sections id with course", sections?._id)
    const handleLectureClick = (lectureId) => {
        navigate('/all-courses/course-detail/content', { state: { id: lectureId, courseId: sections?._id, sections: sections } });
    };
    const convertTime = (decimalMinutes) => {
        if (!decimalMinutes || isNaN(decimalMinutes)) {
            return '0:00';
        }

        const totalSeconds = Math.floor(decimalMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='pt-4'>
            <Row className='d-flex flex-lg-row align-items-center mx-0'>
                <Col md={4}>
                    <CourseHeading heading={"Curriculum"} />
                </Col>
                <Col md={8} className='p-sm-0'>
                    <div className='mx-0 d-flex flex-row gap-md-3 gap-1 justify-content-end'>
                        <div className='px-0'>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <Image className='' src={folder} />
                                </div>
                                <div className='accordian-text'>
                                    {sections?.sections?.length} sections
                                </div>
                            </div>
                        </div>
                        <div className='px-0'>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <Image className='' src={playicon} />
                                </div>
                                <div className='accordian-text'>
                                    {sections?.totalLectureCount} lectures
                                </div>
                            </div>
                        </div>
                        <div className='px-0'>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <Image className='' src={clock} />
                                </div>
                                <div className='accordian-text'>
                                    {(sections?.totalCourseDuration ?? 0).toFixed(2)}m
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='pt-3'>
                {sections?.sections?.map((section, index) => (
                    <Accordian
                        key={index}
                        title={section?.name}
                        playIcon={playicon}
                        clockIcon={clock}
                        lectureCount={section?.lectures?.length}
                        time={`${(section?.totalSectionDuration ?? 0).toFixed(2)}m`}
                    >
                        <div className='pb-4'>
                            {section?.lectures?.length > 0 ? (
                                section?.lectures?.map((lecture, index) => (
                                    <Lecture
                                        key={index}
                                        coursePlaySrc={coursePlay}
                                        courseName={lecture?.name}
                                        time={convertTime(lecture?.video[0]?.duration)}
                                        onClick={() => handleLectureClick(lecture?._id)}
                                    />
                                ))
                            ) : (
                                <div className='pt-4 px-4'>No lectures available in this section.</div>
                            )}
                        </div>
                    </Accordian>
                ))}
            </div>
        </div>
    )
}

export default Curriculum;
