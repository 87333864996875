import { GetApiData } from "../../utils/http-client";

export const CreateCourseContent = function (data) {
    return GetApiData(`/course/create`, 'POST', data, true);
}
export const UpdateCourse = function (data, id) {
    return GetApiData(`/course/edit/${id}`, 'PUT', data, true);
}
export const Categories = function (data) {
    return GetApiData(`/category/`, 'GET', data, true);
}
export const Subcategories = function (data) {
    return GetApiData(`/subcategory/`, 'GET', data, true);
}
export const CreateSection = function (data) {
    return GetApiData(`/section/add`, 'POST', data, true);
}
export const CreateLecture = function (data) {
    return GetApiData(`/lecture/add/`, 'POST', data, true);
}
export const UpdateVideo = function (data, id) {
    return GetApiData(`/lecture/video/${id}`, 'PUT', { video: data }, true);
}
export const Updatefile = function (data, id) {
    return GetApiData(`/lecture/file/${id}`, 'PUT', data, true);
}
export const UpdateCaption = function (data, id) {
    return GetApiData(`/lecture/captions/${id}`, 'PUT', data, true);
}
export const UpdateDescription = function (data, id) {
    return GetApiData(`/lecture/description/${id}`, 'PUT', data, true);
}
export const UpdateLecture = function (data, id) {
    return GetApiData(`/lecture/notes/${id}`, 'PUT', data, true);
}
export const DeleteSection = function (id) {
    return GetApiData(`/section/${id}`, 'DELETE', null, true);
}
export const DeleteLecture = function (id) {
    return GetApiData(`/lecture/${id}`, 'DELETE', null, true);
}
export const GetSection = function (id) {
    return GetApiData(`/course/getcoursebyId/${id}`, 'GET', null, true);
}
export const UpdateSectionName = function (data, id) {
    return GetApiData(`/section/editName/${id}`, 'PUT', data, true);
}
export const UpdateLectureName = function (data, id) {
    return GetApiData(`/lecture/name/${id}`, 'PUT', data, true);
}
export const GetAllCourses = function () {
    return GetApiData(`/course/getmycourses`, 'GET', null, true);
}
export const PublishCourse = function (id, isPublished) {
    return GetApiData(`/course/editIsPublished/${id}/${isPublished}`, 'PUT', null, true);
}
export const DeleteCourse = function (id) {
    return GetApiData(`/course/${id}`, 'DELETE', null, true);
}
export const DeleteMedia = function (data) {
    return GetApiData(`/lecture/media`, 'DELETE', data, true);
}

export const ReviewsCourse = function (id) {
    return GetApiData(`/feedback/getFeedbackByCourse/${id}`, 'GET', null, true);
}
export const CreateReview = function (data) {
    return GetApiData(`/feedback/create`, 'POST', data, true);
}