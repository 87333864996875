import React, { useEffect, useState } from 'react'
import Heading from '../components/sharedComponent/Heading'
import { Card, Col, Row } from 'react-bootstrap'
import CourseCard from '../components/sharedComponent/CourseCard'
import number from '../assests/number.svg'
import topaz from '../assests/topaz.svg'
import enrolled from '../assests/enrolled.svg'
import certificate from '../assests/certificates.svg'
import profile from '../assests/Ellipse 8.png'
import background from '../assests/overlaptop.png'
import { getInProgressCourses, getRecommendedCourses, getUserStats } from '../services/userDashboard/allCourses.service'

const Dashboard = () => {
    const [userStats, setUserStats] = useState({});
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const [inProgressCourses, setInProgressCourses] = useState([])
    const [recommendedCourses, setRecommendedCourses] = useState([])
    const [instructorCard, setInstructorCard] = useState([]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userStatsResponse = await getUserStats()
                console.log("user stats", userStatsResponse.data)
                setUserStats(userStatsResponse.data.data);

                const inProgressCoursesResponse = await getInProgressCourses()
                console.log("progress stats", inProgressCoursesResponse.data)
                setInProgressCourses(inProgressCoursesResponse.data.data)

                const recommendedCoursesResponse = await getRecommendedCourses()
                console.log("recommend stats", recommendedCoursesResponse.data)
                setRecommendedCourses(recommendedCoursesResponse.data.data)
            } catch (error) {
                console.error("Error fetching data:", error)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (userStats) {
            setInstructorCard([
                {
                    img: number,
                    heading: `${userStats.currentStreak?.loginStreak || 0} Days`,
                    text: 'Current Streak'
                },
                {
                    img: topaz,
                    heading: userStats?.currentLevel || 'N/A',
                    text: 'Current Level'
                },
                {
                    img: enrolled,
                    heading: `${userStats?.enrolledCourses || 0}`,
                    text: 'Enrolled Courses'
                },
                {
                    img: certificate,
                    heading: `${userStats?.certificatesEarned || 0}`,
                    text: 'Certificates Earned'
                }
            ])
        }
    }, [userStats])

    const getUsername = (email) => {
        if (!email) return "";
        return email.split("@")[0] + "@";
    };
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };


    if (loading) {
        return <div className="loader-block">
            <div className="loader"></div>
        </div>
    }

    return (
        <div className='py-4'>
            <div>
                <h4 className='poppins-bold primary-color'>Hi  {capitalizeFirstLetter(user?.firstname)}, Welcome Back!</h4>
                <p className='teaching-para'>Let's learn something new today</p>
            </div>
            <div className='pt-4'>
                <Heading heading='Overview' />
                <div className='pt-4'>
                    <Row className='gap-xl-0 gap-lg-0 gap-md-0 gap-3'>
                        {instructorCard.map((stat, index) => (
                            <Col md={6} xl={3} lg={5} key={index} className='pb-md-4'>
                                <Col xl={10} key={index} className=''>
                                    <Card className='py-4 px-2 custom-card-dashboard'>
                                        <Card.Img variant="top" src={stat.img} width={'80px'} height={'80px'} />
                                        <Card.Body>
                                            <Card.Title className='text-center card-heading'>{stat.heading}</Card.Title>
                                            <Card.Text className='text-center card-text-light'>
                                                {stat.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <div className='pt-4'>
                <Heading heading='In-Progress' />
                <div className='pt-3'>
                    <Row className='justify-content-start gap-xl-0 gap-lg-0 gap-md-0 gap-3'>

                        {inProgressCourses?.length === 0 ? (
                            <p>There are no courses currently in progress.</p>
                        ) : (inProgressCourses?.map((course, index) => (
                            <Col lg={6} xl={4} md={6} className='pb-md-4' key={index}>
                                <CourseCard
                                    profile={course?.courseId.profile || profile}
                                    backgroundImage={course?.courseId.thumbnail || background}
                                    name={course.courseId.author || 'Unknown'}
                                    username={getUsername(course?.courseId.authorEmail)}
                                    time={course?.totalDuration}
                                    description={course.courseId.description ? course.courseId.description.replace(/<\/?[^>]+(>|$)/g, "") : 'No description available'}
                                    buttonName="Continue"
                                    title={course?.courseId.title}
                                    fontSize={15}
                                    className='min-Height'
                                    color='#0a66c2'
                                    courseID={course?.courseId._id}

                                />
                            </Col>
                        )))}
                    </Row>
                </div>
            </div>
            <div className='pt-4'>
                <Heading heading='Courses For You' />
                <div className='pt-3'>
                    <Row className='justify-content-between gap-xl-0 gap-lg-0 gap-md-0 gap-3'>
                        {recommendedCourses.map((course, index) => (
                            <Col lg={6} xl={4} md={6} className='pb-md-4' key={index}>
                                <CourseCard
                                    profile={course?.profile || profile}
                                    backgroundImage={course?.thumbnail || background}
                                    name={course.author || 'Unknown'}
                                    username={getUsername(course?.authorEmail)}
                                    time={course?.totalDuration}
                                    description={course.description ? course.description.replace(/<\/?[^>]+(>|$)/g, "") : 'No description available'}
                                    buttonName="View"
                                    title={course?.title}
                                    fontSize={15}
                                    className='min-Height'
                                    color='#0a66c2'
                                    courseID={course?._id}

                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
