import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CourseCreate = ({ label, initialValue, onSave, onHide, show }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (show) {
            setValue(initialValue); // Reset value when initialValue prop changes and modal is shown
        }
    }, [initialValue, show]);

    const handleSave = () => {
        if (value.trim() === '') {
            return;
        }
        onSave(value);
        onHide();
    };

    const handleClose = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className='feedback-header'>Edit {label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicInput" className=''>
                        <Form.Label className='feedback-label mb-2'>{label}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`Enter ${label}`}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={value.trim() === ''}
                    className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                >
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseCreate;
