import React, { useState, useEffect } from 'react';
import logo from '../assests/logo.png';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ForgotPassword, VerificationCode } from '../services/login/login.service';
import OTPInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [timer, setTimer] = useState(10);
    const [showResend, setShowResend] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handleContinueOtp = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Verification", { closeButton: true });
        try {
            await VerificationCode({
                email: email,
                code: otp,
            });
            toast.update(toastId, {
                render: "Code is Succesfully Verified",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            navigate('/reset-password', { state: { email: email, otp: otp } });
        } catch (error) {
            console.log("Incorrect OTP", error);
            toast.update(toastId, {
                render: error?.response?.data?.data?.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
        }
    };

    const handleContinue = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setEmailError('');
        let isValid = true;
        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        if (isValid) {
            const toastId = toast.loading("Sending Email", { closeButton: true });
            try {
                await ForgotPassword({
                    email: email,
                });
                toast.update(toastId, {
                    render: "Email is Succesfully sent",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setCode(true);
                setTimer(120);
                setShowResend(false);
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };

    const handleResendCode = async () => {
        const toastId = toast.loading("Resending Code", { closeButton: true });
        try {
            await ForgotPassword({
                email: email,
            });
            toast.update(toastId, {
                render: "Code is Succesfully Resent",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setTimer(120);
            setShowResend(false);
        } catch (error) {
            toast.update(toastId, {
                render: error.response.data.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setShowResend(true);
        }
    }, [timer]);

    return (
        <div className="login-bg d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col lg={4} md={8}>
                        <div style={{ margin: "auto", width: "200px", marginBottom: "50px" }}>
                        <Link to={"/"}>
                            <Image src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div className='sign-heading text-center pb-3'>
                            {code ? (
                                <p className='poppins-semibold font-28 mb-1'>Verification Code</p>
                            ) : (
                                <p className='poppins-semibold font-28 mb-1'>Forget Password</p>
                            )}
                        </div>
                        {code ? (
                            <Form>
                                <div className='otp-input'>
                                    <OTPInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>

                                <div className="mt-3 text-center">
                                    {showResend ? (
                                        <span style={{ cursor: "pointer", color: "#914AEE" }} className="resend-code" onClick={handleResendCode}>
                                            Resend Code
                                        </span>
                                    ) : (
                                        <span className="timer">
                                            Resend code in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                        </span>
                                    )}
                                </div>
                                <Button variant="primary" type="button" onClick={handleContinueOtp} className='w-100 primary-bg login-button py-2 font-14 mt-4'>
                                    Continue
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Form className='text-center'>
                                    <Form.Group controlId="formBasicEmail" className='pb-3'>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                        <Form.Text className="text-danger">{emailError}</Form.Text>
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={handleContinue} className='w-100 primary-bg login-button py-2 font-14'>
                                        Send Otp
                                    </Button>
                                    <div className='mt-3'>
                                    <Link className="ps-1 text-decoration-none primary-color log-in-link" to={"/login"}>Back to Login</Link>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Col>
                </Row >
            </Container >
        </div >
    )
}

export default ForgetPassword;
