import React from 'react'
import { Row, Col, Image, Container } from 'react-bootstrap'

const SideBySideColumn = ({ heading, text, imageSrc, rowReverse = false }) => {
    return (
        <Container fluid className='py-lg-5 py-4'>
            <Row className={`justify-content-between align-items-center py-lg-5 ${rowReverse ? 'flex-row-reverse' : ''}`}>
                <Col xl={5}>
                    <div>
                        <Col xl={10}>
                            <div>
                                <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                            </div>
                            <div>
                                <p className='para-color pt-2 font-24'>
                                    {text}
                                </p>
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col xl={6}>
                    <div className='image-responsive' style={{}}>
                        <Image src={imageSrc} className='Cognite-img' height={"100%"} width={"100%"} alt='Image' />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SideBySideColumn
