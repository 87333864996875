import React from 'react';
import { Offcanvas, ProgressBar } from 'react-bootstrap';
import CourseHeading from '../../components/sharedComponent/CourseHeading';
import Accordian from '../../components/Curriculum/Accordian';
import Lecture from '../../components/Curriculum/Lecture';
import playicon from '../../../src/assests/PlayCircle.svg';
import clock from '../../../src/assests/Clock.svg';
import coursePlay from '../../assests/Play (1).svg';

const CourseContentOffcanvas = ({ show, handleClose, isAdmin, sections, courseDetails, selectedLectureId, handleLectureChange, handleLectureClick, loading, updatingProgress }) => {
    console.log("Course Details toggle", courseDetails?.course?.manualToggle)
    const convertTime = (decimalMinutes) => {
        if (!decimalMinutes || isNaN(decimalMinutes)) {
            return '0:00';
        }

        const totalSeconds = Math.floor(decimalMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Offcanvas className='custom-offcanvas' show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Course Content</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className='mt-4'>
                    {isAdmin ? (
                        <div className='d-flex align-items-center justify-content-between'>
                            <CourseHeading heading={"Course Content"} />
                        </div>
                    ) : (
                        <div>
                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                <CourseHeading heading={"Course Content"} />
                                <p className='mb-0 completion-text poppins-semibold'>{Math.round(courseDetails?.progress) || 0}% Completed</p>
                            </div>
                            <div>
                                <ProgressBar className='custom-progress' now={Math.round(courseDetails?.progress) || 0} />
                            </div>
                        </div>
                    )}
                    <div className='pt-3'>
                        {sections?.sections?.map((section, index) => (
                            <Accordian
                                key={index}
                                title={section?.name}
                                playIcon={playicon}
                                clockIcon={clock}
                                lectureCount={section?.lectures?.length}
                                time={`${(section?.totalSectionDuration ?? 0).toFixed(2)}m`}
                            >
                                <div className=''>
                                    {section?.lectures?.length > 0 ? (
                                        section?.lectures?.map((lecture, index) => {
                                            const isCompleted = courseDetails?.completedLectures?.some(completedLecture => completedLecture._id === lecture._id);
                                            const isSelected = lecture._id === selectedLectureId;

                                            return (
                                                <Lecture
                                                    key={index}
                                                    time={convertTime(lecture?.video[0]?.duration)}
                                                    timeplay={coursePlay}
                                                    label={lecture?.name}
                                                    value={lecture?._id}
                                                    isCompleted={isCompleted}
                                                    onChange={handleLectureChange}
                                                    onClick={() => handleLectureClick(lecture?._id)}
                                                    checked={isSelected}
                                                    disabled={updatingProgress || loading}
                                                    manualToggle={courseDetails?.course?.manualToggle}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div className='pt-4 px-4'>No lectures available in this section.</div>
                                    )}
                                </div>
                            </Accordian>
                        ))}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CourseContentOffcanvas;
