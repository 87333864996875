import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, FormLabel, Image, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';
import defaultUploader from '../../assests/Uploader.png'
import { PiPlus } from 'react-icons/pi';
import { BsUpload } from 'react-icons/bs';
import TinyEditor from '../RichTextField/TextEditor';
import { uploadUserFile } from '../../services/TeacherDashboard/Dashboard.service';

const AdvanceInformation = forwardRef(({ formData, setFormData, setLoadingSave }, ref) => {
    const [loading, setloading] = useState(false);
    const { courseRequirements = [''], whoThisCourseIsFor = [''], outline = [''], description, video, thumbnail } = formData;
    const [count, setCount] = useState(outline.length || 1);
    const [countContent, setCountContent] = useState(whoThisCourseIsFor.length || 1);
    const [countRequirement, setCountRequirement] = useState(courseRequirements.length || 1);

    useEffect(() => {
        if (outline.length === 0) setFormData((prevData) => ({ ...prevData, outline: [''] }));
        if (whoThisCourseIsFor.length === 0) setFormData((prevData) => ({ ...prevData, whoThisCourseIsFor: [''] }));
        if (courseRequirements.length === 0) setFormData((prevData) => ({ ...prevData, courseRequirements: [''] }));
        // eslint-disable-next-line
    }, []);

    const handleTitleChange = (index, event) => {
        const newOutline = [...outline];
        newOutline[index] = event.target.value;
        setFormData({ ...formData, outline: newOutline });
    };

    const handleContent = (index, event) => {
        const newWhoThisCourseIsFor = [...whoThisCourseIsFor];
        newWhoThisCourseIsFor[index] = event.target.value;
        setFormData({ ...formData, whoThisCourseIsFor: newWhoThisCourseIsFor });
    };

    const handleRequirement = (index, event) => {
        const newRequirement = [...courseRequirements];
        newRequirement[index] = event.target.value;
        setFormData({ ...formData, courseRequirements: newRequirement });
    };

    const addNewInput = () => {
        if (count < 8) {
            const lastOutline = outline[outline.length - 1];
            if (lastOutline && lastOutline.trim() !== '') {
                setCount(count + 1);
                setFormData({ ...formData, outline: [...outline, ''] });
            } else {
                console.log("formDate new", outline)
                toast.warning('Please fill the last learning point before adding a new one.');
            }
        }
    };

    const addNewInputContent = () => {
        if (countContent < 8) {
            const lastContent = whoThisCourseIsFor[whoThisCourseIsFor.length - 1];
            if (lastContent && lastContent.trim() !== '') {
                setCountContent(countContent + 1);
                setFormData({ ...formData, whoThisCourseIsFor: [...whoThisCourseIsFor, ''] });
            } else {
                toast.warning('Please fill the last course point before adding a new one.');
            }
        }
    };

    const addNewInputRequirement = () => {
        if (countRequirement < 8) {
            const lastRequirement = courseRequirements[courseRequirements.length - 1];
            if (lastRequirement && lastRequirement.trim() !== '') {
                setCountRequirement(countRequirement + 1);
                setFormData({ ...formData, courseRequirements: [...courseRequirements, ''] });
            } else {
                toast.warning('Please fill the last requirement before adding a new one.');
            }
        }
    };

    const handleVideoUpload = async (e) => {
        setloading(true);
        setLoadingSave(true);
        e.preventDefault();
        const toastId = toast.loading("Updating", { closeButton: true });
        const file = e.target.files[0];
        e.target.value = null;

        if (file) {
            const fileSizeLimit = 400 * 1024 * 1024;
            if (file.size > fileSizeLimit) {
                toast.update(toastId, {
                    render: "File size limit exceeded (400MB)",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setLoadingSave(false);
                setloading(false);
                return;
            }

            try {
                const formData = new FormData();
                formData.append('attachments', file);
                const response = await uploadUserFile(formData);
                const newVideoSrc = response?.data?.data[0];
                setFormData((prevFormData) => ({ ...prevFormData, video: newVideoSrc }));
                toast.dismiss(toastId);
                setLoadingSave(false);
                setloading(false);
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setLoadingSave(false);
                setloading(false);
            }
        }
    };


    const handleImageUpload = async (e) => {
        setloading(true);
        setLoadingSave(true);
        const toastId = toast.loading("Updating", { closeButton: true });
        const file = e.target.files[0];
        e.target.value = null;

        if (file) {
            const fileSizeLimit = 20 * 1024 * 1024;
            if (file.size > fileSizeLimit) {
                toast.update(toastId, {
                    render: "File size limit exceeded (20MB)",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setLoadingSave(false);
                setloading(false);
                return;
            }

            try {
                const formData = new FormData();
                formData.append('attachments', file);
                const response = await uploadUserFile(formData);
                const newImageSrc = response.data.data[0];
                setFormData((prevFormData) => ({ ...prevFormData, thumbnail: newImageSrc }));
                toast.dismiss(toastId);
                setLoadingSave(false);
                setloading(false);
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setLoadingSave(false);
                setloading(false);
            }
        }
    };


    const validateBasicInformation = () => {
        let isValid = true;

        if (!formData.thumbnail) {
            toast.error('Course thumbnail image is required');
            isValid = false;
        }
        if (!formData.video) {
            toast.error('Course trailer video is required');
            isValid = false;
        }
        if (!formData.description || formData.description.trim() === '') {
            toast.error('Course description is required');
            isValid = false;
        }

        return isValid;
    };

    useImperativeHandle(ref, () => ({
        validate: validateBasicInformation
    }));

    return (
        <div clasName="container" >
            <div className='py-2  border-up px-lg-3'>
                <div className='d-flex align-items-md-center justify-whoThisCourseIsFor-between mb-lg-1 py-md-4 py-2 px-md-4 border-down flex-md-row flex-column'>
                    <div>
                        <h3 className='course-Subheading-small poppins-semibold mb-0'>Advance Information</h3>
                    </div>
                </div>
            </div>
            <div className='pt-3 '>

                <Row >
                    <Col lg={6} className='px-md-2 px-0'>
                        <div className='ps-md-3 ps-0'>
                            <h3 className='course-Subheading-small poppins-semibold'>Course Thumbnail</h3>
                        </div>
                        <Row className='mx-0 pt-3 gap-3'>
                            <Col xs={12} md={5}>
                                <div className='min-img-size-upload'>
                                    <Image src={thumbnail || defaultUploader} className='gallery-img' alt='uploader' />
                                </div>
                            </Col>
                            <Col xs={12} md={6} className='d-flex flex-column justify-content-between gap-md-0 gap-3'>
                                <div className='course-para-light'>
                                    Upload your course Thumbnail here. <span className='text-dark poppins-medium'>Important guidelines:</span> 1200x800 pixels or 12:8 Ratio. Supported format:<span className='text-dark poppins-medium'> .jpg, .jpeg, or .png</span>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        className="download-btn d-flex justify-content-center align-items-center rounded-0 gap-2 px-lg-3 py-lg-2 download-btn-text"
                                        disabled={loading}
                                        onClick={() => document.getElementById('fileInput').click()}
                                    >
                                        Upload Image <BsUpload className="ms-2" />
                                    </Button>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className='pt-lg-0 pt-3 px-md-2 px-0'>
                        <div className='ps-3'>
                            <h3 className='course-Subheading-small poppins-semibold'>Course Trailer</h3>
                        </div>
                        <Row className='mx-0 pt-3 gap-3'>
                            <Col xs={12} md={5}>
                                <div className='min-img-size-upload'>
                                    {video ? (
                                        <video
                                            controls={false}
                                            src={video}
                                            className='gallery-img'
                                        />
                                    ) : (
                                        <Image src={defaultUploader} className='gallery-img w-100' alt='uploader' />
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={6} className='d-flex flex-column justify-content-between gap-md-0 gap-3'>
                                <div className='course-para-light'>
                                    Upload your course Thumbnail here. <span className='text-dark poppins-medium'>Important guidelines:</span> 1200x800 pixels or 12:8 Ratio.<span className='text-dark poppins-medium'></span>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        className="download-btn d-flex justify-content-center align-items-center rounded-0 gap-2 px-lg-3 py-lg-2 download-btn-text"
                                        disabled={loading}
                                        onClick={() => document.getElementById('VideoInput').click()}
                                    >
                                        Upload Video <BsUpload className="ms-2" />
                                    </Button>
                                    <input
                                        id="VideoInput"
                                        type="file"
                                        accept="video/mp4, video/quicktime, video/x-msvideo"
                                        onChange={handleVideoUpload}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
            <div className='pt-3'>
                <Form>
                    <Form.Group controlId="formTitle" className='pb-3 px-lg-3'>
                        <FormLabel className='course-Subheading-small poppins-semibold'>Course Description</FormLabel>
                        <TinyEditor
                            setDescription={(content) => setFormData((prevData) => ({ ...prevData, description: content }))}
                            value={description}

                        />
                    </Form.Group>

                    <div className='d-flex align-items-md-center align-items-end justify-content-between mb-1 px-lg-3 flex-md-row flex-column'>
                        <div>
                            <h3 className='course-Subheading-small poppins-semibold'>
                                What You will learn in this course <span>({count}/8)</span>
                            </h3>
                        </div>
                        <div>
                            <Button
                                className='px-lg-4 py-lg-2 d-flex justify-content-center align-items-center bg-white primary-color menu-btn save-btn-text download-btn-text'
                                onClick={addNewInput}
                            >
                                <PiPlus className="me-2" /> Add new
                            </Button>
                        </div>
                    </div>
                    <div className='px-lg-3'>
                        {outline?.map((title, index) => (
                            <Form.Group key={index} controlId={`formTitle${index}`} className='pb-3'>
                                <FormLabel className='font-14'>{`0${index + 1}`}</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Enter Learning Point ${index + 1}`}
                                        value={title}
                                        onChange={(event) => handleTitleChange(index, event)}
                                        maxLength={120}
                                        className='input-field'
                                    />
                                    <div className="char-count">{title.length}/120</div>
                                </div>
                            </Form.Group>
                        ))}
                    </div>
                    <div className='d-flex align-items-md-center align-items-end justify-content-between mb-1 px-lg-3 flex-md-row flex-column pt-2'>
                        <div>
                            <h3 className='course-Subheading-small poppins-semibold'>
                                Who this course is for <span>({countContent}/8)</span>
                            </h3>
                        </div>
                        <div>
                            <Button
                                className='px-lg-4 py-lg-2 d-flex justify-content-center align-items-center bg-white primary-color menu-btn save-btn-text download-btn-text'
                                onClick={addNewInputContent}
                            >
                                <PiPlus className="me-2" /> Add new
                            </Button>
                        </div>
                    </div>
                    <div className='px-lg-3'>
                        {whoThisCourseIsFor.map((title, index) => (
                            <Form.Group key={index} controlId={`formTitle123${index}`} className='pb-3'>
                                <FormLabel className='font-14'>{`0${index + 1}`}</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Enter Course Point ${index + 1}`}
                                        value={title}
                                        onChange={(event) => handleContent(index, event)}
                                        maxLength={120}
                                        className='input-field'
                                    />
                                    <div className="char-count">{title.length}/120</div>
                                </div>
                            </Form.Group>
                        ))}
                    </div>
                    <div className='d-flex align-items-md-center align-items-end justify-content-between mb-1 px-lg-3 flex-md-row flex-column pt-2'>
                        <div>
                            <h3 className='course-Subheading-small poppins-semibold'>
                                Course Requirement <span>({countRequirement}/8)</span>
                            </h3>
                        </div>
                        <div>
                            <Button
                                className='px-lg-4 py-lg-2 d-flex justify-content-center align-items-center bg-white primary-color menu-btn save-btn-text download-btn-text'
                                onClick={addNewInputRequirement}
                            >
                                <PiPlus className="me-2" /> Add new
                            </Button>
                        </div>
                    </div>
                    <div className='px-lg-3'>
                        {courseRequirements.map((title, index) => (
                            <Form.Group key={index} controlId={`formTitle123${index}`} className='pb-3'>
                                <FormLabel className='font-14'>{`0${index + 1}`}</FormLabel>
                                <div className="position-relative">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Enter Requirement Point ${index + 1}`}
                                        value={title}
                                        onChange={(event) => handleRequirement(index, event)}
                                        maxLength={120}
                                        className='input-field'
                                    />
                                    <div className="char-count">{title.length}/120</div>
                                </div>
                            </Form.Group>
                        ))}
                    </div>
                </Form>
            </div>
        </div>
    )
})

export default AdvanceInformation
