import React, { useState } from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';

const TextModal = ({ type, show, onHide, onSave }) => {
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSave = () => {
        if (text) {
            setIsLoading(false);
            onSave(text);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className='feedback-header'>{type.charAt(0).toUpperCase() + type.slice(1)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <FormLabel className="font-14" >{type.charAt(0).toUpperCase() + type.slice(1)}</FormLabel>
                    <Form.Control
                        placeholder={`Write Your lecture ${type.charAt(0).toUpperCase() + type.slice(1)} here`}
                        as="textarea"
                        rows={3}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={onHide} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={isLoading || !text}
                    className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                >
                    Add {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TextModal;
