import React from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import LMS_Hero1 from '../assests/LMS-hero.png';
import user from '../assests/Users.svg';
import notebook from '../assests/Notebook.svg';
import world from '../assests/GlobeHemisphereWest.svg';
import CaresoulReview from '../components/CaresoulSection/CaresoulReview'
import button_icon from '../assests/Arrow 2.svg'

import check from '../assests/CircleWavyCheck.svg';
import stack from '../assests/Stack.svg';
import laptop from '../assests/laptop.png';
import checkcircle from '../assests/CheckCircle.svg'
import instructor from '../assests/instructor.svg'
import profile from '../assests/profile.svg'
import course from '../assests/course.svg'
import teaching from '../assests/teaching.svg'
import union from '../assests/Union.png'
import arrow from '../assests/ArrowRight.svg'
import help from '../assests/help.png'
import email from '../assests/email.svg'
import CaresoulSection from '../components/CaresoulSection/CaresoulSection';



const Solutions = () => {
    const stats = [
        { img: user, number: '67.1K', text: 'Students' },
        { img: notebook, number: '26K', text: 'Certified Instructors' },
        { img: world, number: '72', text: 'Country Language' },
        { img: check, number: '99.9%', text: 'Success Rate' },
        { img: stack, number: '57', text: 'Trusted companies' },
    ];
    const teachingPoints = [
        { img: checkcircle, heading: 'Flexible Teaching', text: 'Teach your students the way you want. Our platform offers the freedom to design and deliver your courses in a manner that best suits your teaching style.' },
        { img: checkcircle, heading: 'Centralized Management', text: 'Manage your courses and payments all in one place. MYOFLOW provides an integrated system to streamline your administrative tasks.' },
        { img: checkcircle, heading: 'Student Interaction', text: 'Chat with your students effortlessly. Our platform allows seamless communication to enhance the learning experience.' },
    ]
    const instructorCard = [
        { img: instructor, heading: '1. Apply to Become an Instructor', text: 'Start by submitting your application to join our team of expert instructors.' },
        { img: profile, heading: '2. Set Up & Edit Your Profile.', text: 'Customize your profile to showcase your expertise and attract students' },
        { img: course, heading: '3. Create your new course', text: 'Develop and upload your course content, designing it to meet the needs of your learners' },
        { img: teaching, heading: '4. Start teaching', text: 'Begin delivering your course and engaging with students, helping them achieve their goals.' },
    ]
    const helpPoints = [
        { img: arrow, text: 'We offer personalized support to ensure you succeed in your learning journey.' },
        { img: arrow, text: 'For those looking to reboot their work life and try a new profession, we provide the resources you need.' },
        { img: arrow, text: 'Receive expert guidance and advice to help you navigate your courses and career path.' },
        { img: arrow, text: 'Our team is always ready to offer ongoing assistance, ensuring you have the help you need when you need it.' },
    ]
    return (
        <>
            <Container fluid className='px-0 px-lg-5'>
                <div className='px-2 px-lg-5'>
                    <Row className='justify-content-between align-items-center py-2 py-lg-5 mx-0'>
                        <Col xl={6} lg={4}>
                            <div className='image-responsive img-girl'>
                                <Image src={LMS_Hero1} className='Cognite-img' height={"100%"} width={"100%"} alt='Image' />
                            </div>
                        </Col>  <Col xl={6} lg={8}>
                            <div>
                                <Col xl={9} lg={10}>
                                    <div>
                                        <h2 className='admin-heading poppins-semibold lh-1 font-72'>Detailed Descriptions</h2>
                                    </div>
                                    <div>
                                        <p className='para-color pt-2 font-24'>
                                            Explore our comprehensive suite of training solutions designed to meet diverse learning needs and improve performance.

                                        </p>
                                    </div>
                                    <div>
                                        <Button variant="primary" type="submit" className='primary-bg login-button px-4 py-3 font-14 mt-2'>
                                            Get Started
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container fluid className='pb-lg-5 px-2 px-lg-5 py-3'>
                <Row className={`justify-content-between align-items-center pb-lg-5 px-0 px-lg-5`}>
                    <Col xl={5} md={11}>
                        <div>
                            <Col xl={10}>
                                <div className='d-flex align-items-center gap-4 mb-3'>
                                    <div>

                                        <div className='hr-line'></div>
                                    </div>
                                    <p className='text-uppercase mb-0 dark-color testi'>Testimonial</p>
                                </div>
                                <div className='pt-3 pb-1'>
                                    <h2 className='dark-color testi-head'>What They Say?</h2>
                                </div>
                                <div>
                                    <p className='para-color pt-2 font-24'>
                                        MYOFLOW has got more than 100k positive ratings from our users around the world.
                                    </p>
                                    <p className='para-color pt-2 font-24'>
                                        Some of the students and teachers were greatly helped by the Asynch Learning.
                                    </p>
                                    <p className='para-color pt-2 font-24'>
                                        Are you too? Please give your assessment                                            </p>
                                </div>
                                <div className='pt-2 py-3'>
                                    {/* <Button className='asess-btn py-0 px-4 d-flex align-items-center justify-content-center gap-4'>
                                                Write your assesment
                                                <div>
                                                    <Image className='py-4 ps-4' src={button_icon} />
                                                </div>
                                                </Button> */}
                                    {/* <Button className='d-flex align-items-center gap-4 p-0 started-btn'>
                                <div className='py-2 ps-4 poppins-semibold start-btn-text'>Get Started</div>
                                <div className='started-bg py-2 px-3'>
                                    <Image className='' src={started} />
                                </div>
                            </Button> */}
                                    <Button className='d-flex align-items-center gap-4 p-0 asess-btn'>
                                        <div className='py-3 ps-4 poppins-semibold assesment-text'>Write your assesment</div>
                                        <div className='assesment-bg py-3 px-3'>
                                            <Image className='' src={button_icon} />
                                        </div>
                                    </Button>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col xl={6} md={8}>
                        <CaresoulReview />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-yellow py-4'>
                <Container>
                    <Row className='pt-3 mx-0'>
                        {stats.map((stat, index) => (
                            <Col key={index} className='d-flex gap-3'>
                                <div className='svg-size'>
                                    <Image src={stat.img} width={'100%'} height={'100%'} alt='stat' />
                                </div>
                                <div>
                                    <h2 className='poppins-semibold'>{stat.number}</h2>
                                    <p className='poppins-medium' style={{ color: "#4E5566" }}>{stat.text}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5'>
                <Row className={`justify-content-between align-items-center py-lg-5 mx-0`}>
                    <Col className='mx-auto' xl={6} lg={8}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={laptop} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                    <Col xl={5} lg={12} >
                        <div>
                            <Col xl={9} lg={10}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Why you’ll start teaching on MYOFLOW</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        Join MYOFLOW to enjoy flexible teaching, centralized management, and seamless student interaction. Our platform empowers you to design your courses, manage everything in one place, and communicate effectively with your students, making teaching a rewarding experience.
                                    </p>
                                </div>
                                {teachingPoints.map((stat, index) => (
                                    <Col key={index} className='d-flex gap-3 pb-2'>
                                        <div className='svg-size'>
                                            <Image src={stat.img} width={'40px'} height={'40px'} alt='stat' />
                                        </div>
                                        <div>
                                            <h5 className='poppins-medium'>{stat.heading}</h5>
                                            <p className='poppins-regular' style={{ color: "#4E5566" }}>{stat.text}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-brown py-4'>
                <Container>
                    <Row className='justify-content-center pt-5 mx-0'>
                        <Col md={6}>
                            <h2 className='poppins-semibold text-center'>How you'll become successful instructor</h2>
                        </Col>
                    </Row>
                    <Row className='py-5 gap-lg-0 gap-md-0 gap-3 mx-0'>
                        {instructorCard.map((stat, index) => (
                            <Col lg={3} md={6} key={index} className='d-flex pb-md-4 pb-0'>
                                <Card className='pt-4 px-2 custom-card'>
                                    <Card.Img variant="top" src={stat.img} width={'80px'} height={'80px'} />
                                    <Card.Body>
                                        <Card.Title className='text-center card-heading'>{stat.heading}</Card.Title>
                                        <Card.Text className='text-center card-text'>
                                            {stat.text}
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5'>
                <Row className={`justify-content-between align-items-center py-xl-5  px-lg-0 mx-0 flex-column-reverse flex-lg-row px-0 px-lg-5`}>
                    <Col xl={5} lg={8} className='pt-lg-0 pt-4'>
                        <div>
                            <Col lg={9} md={12}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Instructor rules & regulations</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        Our instructors must maintain professionalism, ensure high-quality course content, communicate promptly with students, adhere to platform policies, and promote an inclusive learning environment. These guidelines ensure a consistent, respectful, and engaging experience for all learners.
                                    </p>
                                </div>
                                <div>
                                    <ul>
                                        <li className='pb-2'>Instructors must always uphold a high standard of professionalism in their interactions with students and in the presentation of their course materials.</li>
                                        <li className='pb-2'>Courses should be meticulously prepared and regularly updated to provide accurate, engaging, and valuable content.</li>
                                        <li className='pb-2'>Responding promptly to student inquiries and feedback is crucial. Instructors should be accessible and proactive in their communication.</li>
                                        <li className='pb-2'>All instructors must follow the platform’s guidelines and standards, ensuring a consistent and fair learning environment.</li>
                                    </ul>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col xl={6} lg={4}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={union} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5 bg-yellow'>
                <Row className={`justify-content-between align-items-center py-lg-5 mx-0`}>
                    <Col className='mx-auto lg-pb-0 pb-4' xl={6} lg={4}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={help} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                    <Col xl={5} lg={6}>
                        <div>
                            <Col lg={9} md={12}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Don’t worry we’re always here to help you</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        We provide personalized support, career reboot resources, expert guidance, and ongoing assistance to ensure your success in every step of your learning journey.
                                    </p>
                                </div>
                                {helpPoints.map((stat, index) => (
                                    <Col key={index} className='d-flex pb-1'>
                                        <div className='svg-size'>
                                            <Image src={stat.img} width={'24px'} height={'24px'} alt='stat' />
                                        </div>
                                        <div>
                                            <p className='poppins-regular points' style={{ color: "#4E5566" }}>{stat.text}</p>
                                        </div>
                                    </Col>
                                ))}
                                <Col className='d-flex gap-3 align-items-center mt-3'>
                                    <div className=''>
                                        <Image src={email} width={'56px'} height={'56px'} alt='stat' />
                                    </div>
                                    <div>
                                        <h2 className='poppins-semibold email-head text-uppercase'>Email us, anytime anywhere</h2>
                                        <p className='poppins-medium email-para mb-0'>help.eduguard@gamil.com</p>
                                    </div>
                                </Col>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <CaresoulSection />
        </>
    );
}

export default Solutions;
