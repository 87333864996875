import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import congrats from '../../assests/congrats.svg';
import Certficate from '../Certificate/Certficate';

const CertificateModal = ({ show, onHide, certificate }) => {
    console.log("certificate", certificate);
    return (
        <Modal show={show} onHide={onHide} centered size='lg'>
            <Modal.Header className='justify-content-center align-items-center border-0 pt-lg-4 pt-1'>
                <Modal.Title className='sign-heading text-center pb-2'>
                    <div>
                        <Image src={congrats} alt="Congrats" className='w-25' />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cutom-modal-body'>
                <div>
                    <Modal.Title className='sign-heading text-center pb-3 poppins-bold'>Certificate of Completion</Modal.Title>
                </div>
                {/* <div style={{ transform: 'scale(0.75)', transformOrigin: 'top' }}> */}
                <Certficate
                    firstname={certificate?.userId?.firstname}
                    lastname={certificate?.userId?.lastname}
                    coursename={certificate?.courseId?.title}
                    date={certificate?.createdAt}
                />
                {/* </div> */}
            </Modal.Body>
        </Modal>
    );
};

export default CertificateModal;
