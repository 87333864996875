import React from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import LMS_Hero1 from '../assests/LMS-hero.png';
import user from '../assests/Users.svg';
import notebook from '../assests/Notebook.svg';
import world from '../assests/GlobeHemisphereWest.svg';
import check from '../assests/CircleWavyCheck.svg';
import stack from '../assests/Stack.svg';
import laptop from '../assests/laptop.png';
import checkcircle from '../assests/CheckCircle.svg'
import instructor from '../assests/instructor.svg'
import profile from '../assests/profile.svg'
import course from '../assests/course.svg'
import teaching from '../assests/teaching.svg'
import union from '../assests/Union.png'
import arrow from '../assests/ArrowRight.svg'
import help from '../assests/help.png'
import email from '../assests/email.svg'
import CaresoulSection from '../components/CaresoulSection/CaresoulSection';



const LMS = () => {
    const stats = [
        { img: user, number: '67.1K', text: 'Students' },
        { img: notebook, number: '26K', text: 'Certified Instructors' },
        { img: world, number: '72', text: 'Country Language' },
        { img: check, number: '99.9%', text: 'Success Rate' },
        { img: stack, number: '57', text: 'Trusted companies' },
    ];
    const teachingPoints = [
        { img: checkcircle, heading: 'Tech your students as you want.', text: 'Morbi quis lorem non orci fermentum euismod. Nam sapien tellus, aliquam nec porttitor vel, pellentesque at metus.' },
        { img: checkcircle, heading: 'Manage your course, payment in one place', text: 'Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra, euismod turpis et, elementum enim.' },
        { img: checkcircle, heading: 'Chat with your students', text: 'Nullam mattis lectus ac diam egestas posuere. Praesent auctor massa orci, ut fermentum eros dictum id.' },
    ]
    const instructorCard = [
        { img: instructor, heading: '1. Apply to become instructor.', text: 'Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh cons' },
        { img: profile, heading: '2. Setup & edit your profile.', text: 'Duis non ipsum at leo efficitur pulvinar. Morbi semper nisi eget accumsan ullamcorper.' },
        { img: course, heading: '3. Create your new course', text: 'Praesent congue ornare nibh sed ullamcorper. Proin venenatis tellus non turpis scelerisque.' },
        { img: teaching, heading: '4. Start teaching', text: 'Praesent congue ornare nibh sed ullamcorper. Proin venenatis tellus non turpis scelerisque.' },
    ]
    const helpPoints = [
        { img: arrow, text: 'Sed nec dapibus orci integer nisl turpis, eleifend sit amet aliquam vel.' },
        { img: arrow, text: 'Those who are looking to reboot their work life and try a new profession that.' },
        { img: arrow, text: 'Nunc auctor consequat lorem, in posuere enim hendrerit sed.' },
        { img: arrow, text: 'Duis ornare enim ullamcorper congue.' },
    ]
    return (
        <>
            <Container fluid className='px-0 px-lg-5'>
                <div className='px-2 px-lg-5'>
                    <Row className='justify-content-between align-items-center py-2 py-lg-5 mx-0'>
                        <Col xl={6} lg={8}>
                            <div>
                                <Col xl={9} lg={10}>
                                    <div>
                                        <h2 className='admin-heading poppins-semibold lh-1 font-72'>Become an Admin</h2>
                                    </div>
                                    <div>
                                        <p className='para-color pt-2 font-24'>
                                            Become an instructor & start teaching with 26k certified instructors. Create a success story with 67.1k Students — Grow yourself with 71 countries.
                                        </p>
                                    </div>
                                    <div>
                                        <Button variant="primary" type="submit" className='primary-bg login-button px-4 py-3 font-14 mt-2'>
                                            Get Started
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                        <Col xl={6} lg={4}>
                            <div className='image-responsive img-girl'>
                                <Image src={LMS_Hero1} className='Cognite-img' height={"100%"} width={"100%"} alt='Image' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container fluid className='bg-yellow py-4'>
                <Container>
                    <Row className='pt-3 mx-0'>
                        {stats.map((stat, index) => (
                            <Col key={index} className='d-flex gap-3'>
                                <div className='svg-size'>
                                    <Image src={stat.img} width={'100%'} height={'100%'} alt='stat' />
                                </div>
                                <div>
                                    <h2 className='poppins-semibold'>{stat.number}</h2>
                                    <p className='poppins-medium' style={{ color: "#4E5566" }}>{stat.text}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5'>
                <Row className={`justify-content-between align-items-center py-lg-5 mx-0`}>
                    <Col className='mx-auto' xl={6} lg={8}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={laptop} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                    <Col xl={5} lg={12} >
                        <div>
                            <Col xl={9} lg={10}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Why you’ll start teaching on MYOFLOW</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        Join MYOFLOW to enjoy flexible teaching, centralized management, and seamless student interaction. Our platform empowers you to design your courses, manage everything in one place, and communicate effectively with your students, making teaching a rewarding experience.
                                    </p>
                                </div>
                                {teachingPoints.map((stat, index) => (
                                    <Col key={index} className='d-flex gap-3 pb-2'>
                                        <div className='svg-size'>
                                            <Image src={stat.img} width={'40px'} height={'40px'} alt='stat' />
                                        </div>
                                        <div>
                                            <h5 className='poppins-medium'>{stat.heading}</h5>
                                            <p className='poppins-regular' style={{ color: "#4E5566" }}>{stat.text}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-brown py-4'>
                <Container>
                    <Row className='justify-content-center pt-5 mx-0'>
                        <Col md={6}>
                            <h2 className='poppins-semibold text-center'>How you'll become successful instructor</h2>
                        </Col>
                    </Row>
                    <Row className='py-5 gap-lg-0 gap-md-0 gap-3 mx-0'>
                        {instructorCard.map((stat, index) => (
                            <Col lg={3} md={6} key={index} className='d-flex pb-md-4 pb-0'>
                                <Card className='pt-4 px-2 custom-card'>
                                    <Card.Img variant="top" src={stat.img} width={'80px'} height={'80px'} />
                                    <Card.Body>
                                        <Card.Title className='text-center card-heading'>{stat.heading}</Card.Title>
                                        <Card.Text className='text-center card-text'>
                                            {stat.text}
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5'>
                <Row className={`justify-content-between align-items-center py-xl-5  px-lg-0 mx-0 flex-column-reverse flex-lg-row`}>
                    <Col xl={5} lg={8} className='pt-lg-0 pt-4'>
                        <div>
                            <Col lg={9} md={12}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Instructor rules & regulations</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        Sed auctor, nisl non elementum ornare, turpis orci consequat arcu, at iaculis quam leo nec libero. Aenean mollis turpis velit, id laoreet sem luctus in. Etiam et egestas lorem.
                                    </p>
                                </div>
                                <div>
                                    <ul>
                                        <li className='pb-2'>Sed ullamcorper libero quis condimentum pellentesque.</li>
                                        <li className='pb-2'>Nam leo tortor, tempus et felis non.</li>
                                        <li className='pb-2'>Porttitor faucibus erat. Integer eget purus non massa ultricies pretium ac sed eros.</li>
                                        <li className='pb-2'>Vestibulum ultrices commodo tellus. Etiam eu lectus sit amet turpi.</li>
                                    </ul>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col xl={6} lg={4}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={union} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='py-lg-5 py-4 px-2 px-lg-5 bg-yellow'>
                <Row className={`justify-content-between align-items-center py-lg-5 mx-0`}>
                    <Col className='mx-auto lg-pb-0 pb-4' xl={6} lg={4}>
                        <div className='image-responsive-cognite' style={{}}>
                            <Image src={help} className='Cognite-img' alt='Image' />
                        </div>
                    </Col>
                    <Col xl={5} lg={6}>
                        <div>
                            <Col lg={9} md={12}>
                                <div>
                                    <h2 className='poppins-semibold teaching-cognity-heading'>Don’t worry we’re always here to help you</h2>
                                </div>
                                <div className='pb-3'>
                                    <p className='teaching-para pt-2 font-24'>
                                        Mauris aliquet ornare tortor, ut mollis arcu luctus quis. Phasellus nec augue malesuada, sagittis ligula vel, faucibus metus. Nam viverra metus eget nunc dignissim.
                                    </p>
                                </div>
                                {helpPoints.map((stat, index) => (
                                    <Col key={index} className='d-flex pb-1'>
                                        <div className='svg-size'>
                                            <Image src={stat.img} width={'24px'} height={'24px'} alt='stat' />
                                        </div>
                                        <div>
                                            <p className='poppins-regular points' style={{ color: "#4E5566" }}>{stat.text}</p>
                                        </div>
                                    </Col>
                                ))}
                                <Col className='d-flex gap-3 align-items-center mt-3'>
                                    <div className=''>
                                        <Image src={email} width={'56px'} height={'56px'} alt='stat' />
                                    </div>
                                    <div>
                                        <h2 className='poppins-semibold email-head text-uppercase'>Email us, anytime anywhere</h2>
                                        <p className='poppins-medium email-para mb-0'>help.eduguard@gamil.com</p>
                                    </div>
                                </Col>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <CaresoulSection />
        </>
    );
}

export default LMS;
