import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

export const AccordionExample = (props) => {
    const [accordionState, setAccordionState] = useState(true);

    const handleAccordionClick = () => {
        setAccordionState(!accordionState);
    };

    const accordionTitle = {
        fontSize: "12px",
        cursor: "pointer",
        fontWeight:"800"
    };

    const accordionContent = {
        padding: "0px"
    };

    return (
        <Accordion defaultActiveKey={accordionState ? "0" : null} className="accordian-custom mb-3">
            <Card className="border-0">
                <Accordion.Header className="Custom-header-accordian " onClick={handleAccordionClick} style={accordionTitle}>
                    {props.title}
                </Accordion.Header>
                <Accordion.Body style={accordionContent}>
                    {props.children}
                </Accordion.Body>
            </Card>
        </Accordion>
    );
};
