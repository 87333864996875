import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const CourseCard = ({ profile, backgroundImage, title, certficate, name, username, time, description, buttonName, fontSize, className, color, courseID }) => {
    const isNumber = !isNaN(time) && time !== null && time !== undefined;
    // console.log("courseId", courseID)
    const [idCourse, setIdCourse] = useState(courseID);
    useEffect(() => {
        if (courseID) {
            setIdCourse(courseID);
        }
    }, [courseID]);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/all-courses/course-detail', { state: { id: idCourse } });
    }
    const truncateHTML = (htmlString, maxLength) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        if (textContent.length > maxLength) {
            return textContent.substring(0, maxLength) + '...';
        }
        return textContent;
    };
    const truncatedDescription = truncateHTML(description, 60);


    return (
        <Card className={`custom-card-overlay ${className ? className : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Card.Body className='p-lg-4 p-4 d-flex justify-content-between flex-column'>
                <Row style={{ zIndex: '2' }}>
                    {/* <Card.Title className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center gap-2'>
                            <div>
                                <Image src={profile} width="100" className="rounded-circle profil-img" />
                            </div>
                            <div>
                                <p className='mb-1 text-white name-font' style={{ fontSize: `${fontSize}px` }}>{name}</p>
                                <p className='mb-0 text-white name-font' style={{ fontSize: `${fontSize}px` }}>@{username}</p>
                            </div>
                        </div>
                        <div>
                            <Button
                                className='border-0'
                                style={{ backgroundColor: color || '#FB6D3A', fontSize: `${fontSize}px` }}
                            >
                                {time}
                                {!(time === 'Published' || time === 'Draft') && ' min'}
                            </Button>

                        </div>
                    </Card.Title> */}
                    <div>
                        <Button
                            className='border-0'
                            style={{ backgroundColor: color || '#FB6D3A', fontSize: `${fontSize}px` }}
                        >
                            {isNumber ? parseFloat(time).toFixed(2) : time}
                            {isNumber && !(time === 'Published' || time === 'Draft') && ' m'}
                        </Button>

                    </div>
                </Row>
                <Row>
                    <div className='card-bg-overlay'>
                        {certficate ? (
                            <>
                                <Row >
                                    <Col xs={4} style={{ zIndex: '2' }}>
                                        <Button className='border-0 complete-btn w-100' onClick={handleNavigate} style={{ backgroundColor: color ? color : '#FB6D3A', fontSize: `${fontSize}px` }}>
                                            {buttonName}
                                        </Button>
                                    </Col>
                                    <Col xs={8} style={{ zIndex: '2' }}>
                                        <div className='d-flex gap-2'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <Image src={certficate} width={20} height={20} />
                                            </div>
                                            <div>
                                                <p className="mb-0 text-white" style={{ fontSize: `${fontSize}px` }} dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                            </>
                        ) : (
                            <Row className='py-lg-4 py-3 gap-lg-0 gap-2 align-items-center'>
                                <Col lg={4} style={{ zIndex: '2' }}>
                                    <Button className='border-0 w-100' onClick={handleNavigate} style={{ backgroundColor: color ? color : '#FB6D3A', fontSize: `${fontSize}px` }}>
                                        {buttonName}
                                    </Button>
                                </Col>
                                <Col lg={8} style={{ zIndex: '2' }}>
                                    <p className='mb-0 text-white' style={{ fontSize: `${fontSize}px` }}>{title}</p>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CourseCard
