import React, { useEffect, useState } from 'react'
import LMS_Hero1 from '../assests/Fit.png'
import { Button, Card, Col, Form, FormLabel, Image, Row } from 'react-bootstrap'
import { MdOutlineEmail } from 'react-icons/md'
import image from '../assests/Branches.png'
import image1 from '../assests/Branches (1).png'
import image2 from '../assests/Branches (2).png'
import image3 from '../assests/Branches (3).png'
import { toast } from 'react-toastify'
import { contactForm } from '../services/contact/contact.service';
import { useLocation } from 'react-router-dom'
const HelpSupport = () => {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const userEmail = "ahmad@gmail.com"
    const handleCopyEmail = async () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        try {
            await navigator.clipboard.writeText(userEmail);
            toast.update(toastId, {
                render: "Email is Succesfully Copied",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        } catch (err) {
            toast.update(toastId, {
                render: err,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        }
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('');
    };
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setMessageError('');
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };
    const handleSignUp = async (e) => {
        e.preventDefault();
        setNameError('');
        setLastNameError('');
        setEmailError('')

        let isValid = true;
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        // if (lastName.trim() === '') {
        //     setLastNameError('Please enter your Last name.');
        //     isValid = false;
        // }
        if (name.trim() === '') {
            setNameError('Please enter your First name.');
            isValid = false;
        }
        if (message.trim() === '') {
            setMessageError('Please enter your Message.');
            isValid = false;
        }
        if (isValid) {
            const toastId = toast.loading("Sending Message", { closeButton: true });
            try {
                await contactForm({
                    firstname: name,
                    lastname: lastName,
                    email: email,
                    message: message
                });
                toast.update(toastId, {
                    render: "Message is sent Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setName('')
                setLastName('')
                setEmail('')
                setMessage('')
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    const images = [
        { img: image, subtitle: 'Moscow, Russia', text: 'Anjeliersstraat 470H, 1015 NL Moscow, Russia' },
        { img: image1, subtitle: 'Tokyo, Japan', text: '901 N Pitt Str., Suite 170 Tokyo, VA 22314, Japan' },
        { img: image2, subtitle: 'Moscow, Russia', text: 'Anjeliersstraat 470H, 1015 NL Moscow, Russia' },
        { img: image3, subtitle: 'Mumbai, India', text: '36 East 20th St, 6th Floor Mumbai, India' },
    ]
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <div className='pt-4'>
            <Row className='justify-content-between align-items-center mx-0 flex-column-reverse flex-lg-row gap-lg-0 gap-4'>
                <Col xl={6} lg={8}>
                    <div>
                        <Col xl={9} lg={10}>
                            <div>
                                <h2 className='admin-heading poppins-semibold lh-1 font-44'>Connect With Us</h2>
                            </div>
                            <div>
                                <p className='para-color pt-2 font-24'>
                                    Want to chat? We’d love to hear from you! Get in touch with our Customer Success Team to inquire about speaking events, advertising rates, or just say hello.
                                </p>
                            </div>
                            <div>
                                <Button
                                    variant="primary"
                                    className="login-button primary-bg d-flex justify-content-center align-items-center email-text py-2 px-3"
                                    onClick={handleCopyEmail}
                                >
                                    <MdOutlineEmail className="me-2" /> Copy Email
                                </Button>
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col xl={6} lg={4}>
                    <div className='image-responsive'>
                        <Image src={LMS_Hero1} className='Cognite-img' height={"100%"} width={"100%"} alt='Image' />
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-center pt-5 mx-0'>
                <Col xl={6} className='pt-lg-4'>
                    <h2 className='poppins-semibold text-center branch-heading'>Our branches all over the world.</h2>
                    <Row className='mx-0 justify-content-center'>
                        <Col md={10}>
                            <div className='pb-3'>
                                <p className='subscribe-heading pt-2 text-center' style={{ color: '#4E5566' }}>
                                    Phasellus sed quam eu eros faucibus cursus. Quisque mauris urna, imperdiet id leo quis, luctus auctor nisi.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className='pt-4'>
                <Row className='mx-0'>
                    {images.map((stat, index) => (
                        <Col key={index} xs={12} md={6} xl={3} className='pb-4 change-column'>
                            <div className='min-img-size card-item'>
                                <Image src={stat.img} className='gallery-img' alt='gallery' />
                            </div>
                            <div className='px-lg-4 card-img-main'>
                                <Card className='img-card'>
                                    <Card.Body className='text-center'>
                                        {/* {stat.title && (
                                            <Card.Title className='card-img-text primary-color'>{stat.title}</Card.Title>
                                        )} */}
                                        <Card.Subtitle className="mb-2 card-img-text" style={{ color: '#1D2026' }}>{stat.subtitle}</Card.Subtitle>
                                        <Card.Text className='card-img-text' style={{ color: '#6E7485' }}>
                                            {stat.text}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='contact-bg py-lg-5' id='contactForm'>
                <Row className='mx-0' >
                    <div className='pb-lg-5 pb-3'>
                        <h2 className='poppins-semibold text-center branch-heading pt-5'>Contact Us</h2>
                    </div>
                    <Col xl={6}>
                        <Row className='mx-0 justify-content-xl-center'>
                            <Col lg={8} className="contact-us-form second-row-margin mb-3">
                                <p className='pb-lg-3'>Will you be in Los Angeles or any other branches any time soon? Stop by the office! We'd love to meet.</p>
                                <Row className='mx-0 pb-lg-4 justify-content-between'>
                                    <Col lg={4}>
                                        <div>
                                            <p className="address-heading poppins-semibold  mb-0">Address</p>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className='adress-content'>
                                            <p className="mb-0">1702 Olympic Boulevard Santa Monica, CA 90404</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mx-0 py-lg-4 py-3 justify-content-between'>
                                    <Col lg={4}>
                                        <div>
                                            <p className="address-heading mb-0 poppins-semibold ">Phone Number</p>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className='adress-content'>
                                            <p className="mb-0">(480) 555-0103</p>
                                        </div>
                                        <div className='adress-content'>
                                            <p className="mb-0">(480) 555-0103</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mx-0 py-lg-4 py-3 justify-content-between'>
                                    <Col lg={4}>
                                        <div>
                                            <p className="address-heading mb-0 poppins-semibold ">Email address</p>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className='adress-content'>
                                            <p className="mb-0">help.eduguard@gmail.com</p>
                                            <p className="mb-0">career.eduguard@gamil.com</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={5} className="" >
                        <Card className="mb-5 mb-lg-0">
                            <Card.Body className="p-4">
                                <Form>
                                    <Row>
                                        <p className='mb-1 adress-content'>Get In Touch</p>
                                        <p className='mb-4 adress-text'>Feel free contact with us, we love to make new partners & friends</p>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicfirst" className='pb-3'>
                                                <FormLabel className='font-14' for="email">First Name</FormLabel>
                                                <Form.Control type="text" placeholder="First Name" className='input-field' value={name} onChange={handleNameChange} />
                                                <Form.Text className="text-danger">{nameError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicLast" className='pb-3'>
                                                <FormLabel className='font-14' for="email">Last Name</FormLabel>
                                                <Form.Control type="text" placeholder="Last Name" className='input-field' value={lastName} onChange={handleLastNameChange} />
                                                <Form.Text className="text-danger">{lastNameError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasicEmail" className='pb-4'>
                                                <FormLabel className='font-14' for="email">Email</FormLabel>
                                                <Form.Control type="email" placeholder="Email Address" className='input-field' value={email} onChange={handleEmailChange} />
                                                <Form.Text className="text-danger">{emailError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasicMessage" className='pb-3'>
                                                <FormLabel className='font-14'>Message</FormLabel>
                                                <Form.Control

                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Message Subject"
                                                    value={message}
                                                    onChange={handleMessageChange}
                                                />
                                                <Form.Text className="text-danger">{messageError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Button className='d-flex align-items-center gap-4 login-button primary-bg me-lg-0 me-2 py-2 px-3' onClick={handleSignUp}>
                                                <div className=' start-btn-text text-small'>Submit</div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div>
                <div className="map">
                    <Image src="" alt="" />
                </div>
            </div>
        </div>
    )
}

export default HelpSupport
