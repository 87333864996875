import React, { useEffect, useState } from 'react'
import icon1 from '../../assests/dashIcons/1.svg'
import icon2 from '../../assests/dashIcons/2.svg'
import icon3 from '../../assests/dashIcons/3.svg'
import icon4 from '../../assests/dashIcons/4.svg'
import { ButtonGroup, Card, Col, Dropdown, Image, Row } from 'react-bootstrap'
import Heading from '../../components/sharedComponent/Heading'
import { GetDashbaord, GetTimestamp } from '../../services/TeacherDashboard/Dashboard.service'
import message from '../../assests/message.svg'
import LineChartGraph from '../../components/Chart/LineChart'
import moment from 'moment'
const TeacherDashboard = () => {
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("Today");
    const [selectedTimePeriodGraph, setSelectedTimePeriodGraph] = useState("Today");
    const [instructorCardData, setInstructorCardData] = useState({
        activatedCoursesCount: 0,
        completedCourses: 0,
        totalUsers: 0,
        trendingCoursesCount: 0
    });
    const [timestamp, settimestamp] = useState();
    const instructorCard = [
        { img: icon1, heading: instructorCardData.activatedCoursesCount, text: 'Active Courses' },
        { img: icon2, heading: instructorCardData.completedCourses, text: 'Completed Courses' },
        { img: icon3, heading: instructorCardData.totalUsers, text: 'Students' },
        { img: icon4, heading: instructorCardData.trendingCoursesCount, text: 'Trending Courses' },
    ]
    const fetchDetail = async () => {
        try {
            const response = await GetDashbaord();
            setInstructorCardData(response?.data?.data);
            console.log("instructor data", response.data.data)
            // setLoading(false)
        } catch (error) {
            if (error?.response?.data?.code === 404) {
                // handle error
            }
        } finally {
            // setLoading(false);
        }
    }
    const fetchTimestamp = async () => {
        try {
            const response = await GetTimestamp();
            console.log("Timestamp data", response?.data?.data)
            settimestamp(response?.data?.data)
            // setLoading(false)
        } catch (error) {
            if (error?.response?.data?.code === 404) {
                // handle error
            }
        } finally {
            // setLoading(false);
        }
    }
    // const toggleTimePeriod = (timePeriod) => {
    //     setSelectedTimePeriod(timePeriod);
    // };
    // const toggleTimePeriodGraph = (timePeriod) => {
    //     setSelectedTimePeriodGraph(timePeriod);
    // };
    useEffect(() => {
        fetchDetail();
        fetchTimestamp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='py-4'>
            <div>
                <h4 className='poppins-bold primary-color'>Hi Admin, Welcome Back!</h4>
                <p className='teaching-para'>Lets learn something new today</p>
            </div>
            <div className='pt-4'>
                <Heading heading='Overview' />
                <div className='pt-4'>
                    <Row className='gap-xl-0 gap-lg-0 gap-md-0 gap-3'>
                        {instructorCard.map((stat, index) => (
                            <Col md={6} xl={3} lg={5} key={index} className='pb-md-4'>
                                <Col xl={10} key={index} className=''>
                                    <Card className='py-4 px-2 custom-card-dashboard'>
                                        <Card.Img variant="top" src={stat.img} width={'80px'} height={'80px'} />
                                        <Card.Body>
                                            <Card.Title className='text-center card-heading'>{stat.heading}</Card.Title>
                                            <Card.Text className='text-center card-text-light'>
                                                {stat.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <div className='pt-4'>
                <Row className='mx-0 justify-content-between'>
                    <Col xl={5}>
                        <div className='border-down'>
                            <div className='d-flex align-items-center justify-content-between mb-1 px-lg-3'>
                                <div>
                                    <h3 className='course-Subheading-small poppins-semibold'>Recent Activity</h3>
                                </div>
                            </div>
                        </div>
                        <div className='timestamp-container'>
                            {timestamp?.length === 0 ? (
                                <div className="p-4">
                                    <p>No timestamp found</p>
                                </div>) : (
                                timestamp?.map((stat, index) => (
                                    <div className='pt-3 d-flex px-lg-3 timestamp-container' key={index}>
                                        <div className='svg-size'>
                                            <Image src={message} width={'32px'} height={'32px'} alt='stat' />
                                        </div>
                                        <div className='px-3'>
                                            <p className='mb-0'>{stat.note}</p>
                                            <p className='card-text-light'>{moment(stat.createdAt).fromNow()}</p>
                                        </div>
                                    </div>
                                )))}
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className='border-down me-lg-5'>
                            <div className='d-flex align-items-center justify-content-between mb-1 px-lg-3'>
                                <div>
                                    <h3 className='course-Subheading-small poppins-semibold'>Course Overview</h3>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3 d-flex pe-lg-3'>
                            <LineChartGraph />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TeacherDashboard
