import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import filter from "../assests/filter.svg";
import Heading from "../components/sharedComponent/Heading";
import CourseCard from "../components/sharedComponent/CourseCard";
import profile from "../assests/Ellipse 8.png";
import background from "../assests/overlaptop.png";
import SearchCourse from "../components/Filters/SearchCourse/SearchCourse";
import Pagination from "../components/Filters/Pagination/Pagination";
import SortingBy from "../components/Filters/Sort/SortingBy";
import { GetAllCoursesForUser } from "../services/userDashboard/allCourses.service";
import { Categories } from "../services/createCourse/createCourse.service";
import FilterOffcanvas from "../components/Filters/FilterOffcanvas";

const AllCourses = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [course, setCourse] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const limit = 6;
  const activeFilterCount = Object.keys(checkedValues).filter(
    (key) => checkedValues[key]?.length
  ).length;

  useEffect(() => {
    const initialFilters = getFiltersFromURL();
    setCheckedValues(initialFilters);
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchAllCourses(page, checkedValues);
  }, [checkedValues, page, sortValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchAllCourses(1, checkedValues);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
    setPage(1);
    updateURLParams({ ...checkedValues, sortOrder: e.target.value });
  };

  const updateURLParams = (filters) => {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        filters[key].forEach((value) => params.append(key, value));
      } else {
        params.append(key, filters[key]);
      }
    }
    window.history.replaceState(null, "", `?${params.toString()}`);
  };

  const handleCheckboxChange = (section, value) => {
    setCheckedValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!updatedValues[section]) {
        updatedValues[section] = [];
      }
      if (updatedValues[section].includes(value)) {
        updatedValues[section] = updatedValues[section].filter(
          (item) => item !== value
        );
      } else {
        updatedValues[section].push(value);
      }
      setPage(1);
      updateURLParams({ ...updatedValues, sortOrder: sortValue });
      return updatedValues;
    });
  };

  const fetchAllCourses = async (page, filters) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page,
        limit,
        sortOrder: sortValue,
        search: searchValue,
        ...filters,
      }).toString();

      const response = await GetAllCoursesForUser(params);
      setCourse(response?.data?.data.courses || []);
      setCount(response.data.data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await Categories();
      setCategories(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getFiltersFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    const filters = {};
    for (const [key, value] of params.entries()) {
      if (!filters[key]) {
        filters[key] = [];
      }
      filters[key].push(value);
    }
    return filters;
  };

  const getUsername = (email) => {
    if (!email) return "";
    return email.split("@")[0] + "@";
  };

  return (
    <div className="py-4">
      <FilterOffcanvas
        show={showFilter}
        handleClose={() => setShowFilter(false)}
        categories={categories}
        checkedValues={checkedValues}
        handleCheckboxChange={handleCheckboxChange}
      />

      <Row className="mx-0 gap-lg-0 gap-xl-0 gap-md-0 gap-3">
        <Col xl={8} md={7} lg={6} className="px-0">
          <Row className="mx-0 gap-5 justify-content-xl-start justify-content-lg-start justify-content-between">
            <Col xl={2} lg={6} md={3} className="px-0">
              <Button
                className="bg-white d-flex gap-3 align-items-center filter-btn"
                onClick={() => setShowFilter(true)}
              >
                <div>
                  <Image className="" src={filter} />
                </div>
                <div className="primary-color">Filter</div>
                <div className="primary-bg count-filter d-flex align-items-center justify-content-center">
                  <span>{activeFilterCount}</span>
                </div>
              </Button>
            </Col>
            <Col xl={8} lg={12} md={8} className="px-0">
              <SearchCourse
                searchValue={searchValue}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={6} md={5}>
          <SortingBy sortValue={sortValue} onChange={handleSortChange} />
        </Col>
      </Row>
      <Row className="py-4 mx-0 justify-content-end">
        <Col lg={12} className="text-end">
          {!loading && (
            <div className="sort-heading text-color-light">
              <span className="poppins-medium" style={{ color: "#1D2026" }}>
                {count}
              </span>{" "}
              results found
            </div>
          )}
        </Col>
      </Row>
      <Row className="mx-0">
        <Col md={12}>
          <div className="">
            <Heading heading="Courses For You" />
            <div className="py-3">
              {loading ? (
                <div className="loader-block">
                  <div className="loader"></div>
                </div>
              ) : course?.length === 0 ? (
                <div className="">
                  <p>No Courses found</p>
                </div>
              ) : (
                <>
                  <Row className="gap-lg-0 gap-xl-0 gap-md-0 gap-3">
                    {course?.map((course, courseIndex) => (
                      <Col
                        key={courseIndex}
                        // xxl={3}
                        xl={4}
                        lg={6}
                        md={6}
                        className="mb-4"
                      >
                        <CourseCard
                          profile={course?.profile || profile}
                          backgroundImage={course?.thumbnail || background}
                          name={course.author}
                          username={getUsername(course.authorEmail)}
                          time={course.totalDuration}
                          description={course?.description}
                          title={course?.title}
                          buttonName="View"
                          fontSize={12}
                          className="min-Height"
                          // color="#0a66c2"
                          color="#0a66c2"
                          courseID={course?._id}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Pagination
                    page={page}
                    total={count}
                    limit={limit}
                    setPage={(page) => setPage(page)}
                  />
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AllCourses;
