import React from 'react'

const TermsHeadingText = ({ heading, text }) => {
    return (
        <div className='pt-lg-4 pt-3'>
            <h2 className='terms-heading poppins-bold'>{heading}</h2>
            <p className='terms-text poppins-regular'>
                {text}
            </p>
        </div>
    )
}

export default TermsHeadingText
