import React, { useState } from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { uploadUserFile } from '../../services/TeacherDashboard/Dashboard.service';
import TinyEditor from '../RichTextField/TextEditor';

const NotesUploadModal = ({ show, onHide, onSave }) => {
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [notes, setNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = () => {
        if (files.length > 0 || notes) {
            onSave(files, notes);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const handleFileUpload = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const toastId = toast.loading("Uploading", { closeButton: true });
        const uploadedFiles = e.target.files ? Array.from(e.target.files) : Array.from(e.dataTransfer.files);
        e.target.value = null;

        const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

        if (uploadedFiles.length > 0) {
            const validFiles = [];
            const invalidFiles = [];

            for (const uploadedFile of uploadedFiles) {
                if (uploadedFile.size <= maxFileSize) {
                    validFiles.push(uploadedFile);
                } else {
                    invalidFiles.push(uploadedFile);
                }
            }

            if (invalidFiles.length > 0) {
                toast.update(toastId, {
                    render: `Some files exceed the 10MB limit: ${invalidFiles.map(file => file.name).join(', ')}`,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }

            if (validFiles.length > 0) {
                try {
                    const newFiles = [];
                    const newFileNames = [];
                    for (const validFile of validFiles) {
                        const formData = new FormData();
                        formData.append('attachments', validFile);
                        const response = await uploadUserFile(formData);
                        const newFileSrc = response?.data?.data[0];
                        newFiles.push(newFileSrc);
                        newFileNames.push(validFile.name);
                    }
                    setFiles([...files, ...newFiles]);
                    setFileNames([...fileNames, ...newFileNames]);
                    toast.update(toastId, {
                        render: "Files uploaded successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } catch (error) {
                    toast.update(toastId, {
                        render: error.response.data.error,
                        type: "error",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                }
            }
        }
        setIsLoading(false);
    };


    return (
        <Modal show={show} onHide={onHide} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='feedback-header'>Lecture Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='mb-5'>
                    <FormLabel className="font-14">Notes</FormLabel>
                    {/* <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder={`Write Your lecture notes here`}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    /> */}
                    <TinyEditor
                        setDescription={(content) => setNotes(content)}
                        value={notes}
                    />
                </Form.Group>
                <Form.Group>
                    <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        style={{
                            border: '1px solid #E9EAF0',
                            borderRadius: '6px',
                            padding: '24px',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => document.getElementById('FileInput').click()}
                    >
                        <p className='text-dark course-para-light mb-1'>Upload Notes</p>
                        <p className='course-para-light'>Drag and drop files or browse files</p>
                        <input
                            id="FileInput"
                            type="file"
                            accept="application/pdf,.csv,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                </Form.Group>
                {fileNames.length > 0 && (
                    <div className='mt-3'>
                        <p className='text-success mb-1'>Files Uploaded Successfully</p>
                        {fileNames.map((fileName, index) => (
                            <p key={index} className='text-dark mb-1'>{fileName}</p>
                        ))}
                        <button
                            variant="link"
                            className="p-0 primary-color bg-white border-0 text-decoration-none pt-2"
                            onClick={() => {
                                setFiles([]);
                                setFileNames([]);
                            }}
                        >
                            Replace Files
                        </button>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={onHide} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={isLoading || (files.length === 0 && !notes)}
                    className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                >
                    Add Notes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotesUploadModal;
