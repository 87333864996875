import React, { useState } from 'react';
import CourseHeading from '../sharedComponent/CourseHeading';
import CourseContent from '../sharedComponent/CourseContent';
import { Button } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';

const LectureNotes = ({ lecture }) => {
    const [loading, setLoading] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(null);

    const downloadFile = async (fileUrl, fileName) => {
        setLoading(true);
        setDownloadingFile(fileName);
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('File download failed', error);
        }
        setLoading(false);
        setDownloadingFile(null);
    };

    return (
        <>
            <div className='pt-4'>
                <div className='d-flex align-items-center justify-content-between pb-3'>
                    <CourseHeading heading={"Lecture Notes"} />
                </div>
                {lecture?.notes?.description ? (
                    <CourseContent text={lecture?.notes?.description} />
                ) : (
                    <CourseContent text={'Lecture Notes are not available'} />
                )}
                {lecture?.notes?.file && lecture.notes.file.length > 0 ? (
                    lecture.notes.file.map((fileUrl, index) => (
                        <div key={index} className='d-flex align-items-center justify-content-between pb-3'>
                            <p className='mb-0'>File {index + 1}</p>
                            <Button
                                variant="primary"
                                className="download-btn d-flex justify-content-center align-items-center"
                                onClick={() => downloadFile(fileUrl, `lecture-notes-${index + 1}.pdf`)}
                                disabled={loading && downloadingFile === `lecture-notes-${index + 1}.pdf`}
                            >
                                <BsDownload className="me-2" /> {loading && downloadingFile === `lecture-notes-${index + 1}.pdf` ? 'Downloading...' : 'Download Notes'}
                            </Button>
                        </div>
                    ))
                ) : (
                    <CourseContent text={'Lecture Notes files are not available'} />
                )}
            </div>
        </>
    );
}

export default LectureNotes;
