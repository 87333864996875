import { CgProfile } from 'react-icons/cg';
import { HiOutlinePresentationChartLine } from 'react-icons/hi';
import { LuBadgeHelp } from 'react-icons/lu';
import { PiBookOpenTextThin } from 'react-icons/pi';
import { RiHomeLine } from 'react-icons/ri';
// import { GetUserRoles } from '../../utils/auth.utils';

export const menuItem = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <RiHomeLine />,
        visibleOn: ["user"]
    },
    {
        path: "/all-courses",
        name: "All Courses",
        icon: <PiBookOpenTextThin />,
        visibleOn: ["user"]
    },
    {
        path: "/completed-course",
        name: "Completed",
        icon: <HiOutlinePresentationChartLine />,
        visibleOn: ["user"]
    },
    {
        path: "/profile",
        name: "Profile",
        icon: <CgProfile />,
        visibleOn: ["user"]
    },
    {
        path: "/help",
        name: "Help & support",
        icon: <LuBadgeHelp />,
        visibleOn: ["user"]
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <RiHomeLine />,
        visibleOn: ["admin"]
    },
    {
        path: "/create-course",
        name: "Create New Course",
        icon: <HiOutlinePresentationChartLine />,
        visibleOn: ["admin"]
    },
    {
        path: "/all-courses",
        name: "My Courses",
        icon: <PiBookOpenTextThin />,
        visibleOn: ["admin"]
    },
    {
        path: "/profile",
        name: "Profile",
        icon: <CgProfile />,
        visibleOn: ["admin"]
    },
    {
        path: "/help",
        name: "Help & support",
        icon: <LuBadgeHelp />,
        visibleOn: ["admin"]
    },
]


