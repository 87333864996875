import React from 'react'
import CourseHeading from '../sharedComponent/CourseHeading'
import CourseContent from '../sharedComponent/CourseContent'

const Description = ({ lecture }) => {
    return (
        <div className='pt-4'>
            <CourseHeading heading={"Lectures Description"} />
            {lecture?.description ? (
                <CourseContent text={lecture?.description} />
            ) : (
                <CourseContent text={'Lecture Description is not available'} />
            )}
        </div>
    )
}

export default Description
