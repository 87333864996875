import React from 'react';
import styles from "./styles.module.css";
import arrowRight from '../../../assests/ArrowRight.svg'
import arrowLeft from '../../../assests/ArrowLeft.svg'
import { Image } from 'react-bootstrap';

const Pagination = ({ page, total, limit, setPage }) => {
    const totalPages = Math.ceil(total / limit);
    console.log(totalPages);
    const maxVisiblePages = 5; // Number of page buttons to show

    const onClick = (newPage) => {
        setPage(newPage);
    };

    const handlePrevClick = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextClick = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const getVisiblePages = () => {
        const pages = [];
        const half = Math.floor(maxVisiblePages / 2);

        let start = Math.max(1, page - half);
        let end = Math.min(totalPages, page + half);

        if (page - half < 1) {
            end = Math.min(totalPages, end + (half - (page - 1)));
        }
        if (page + half > totalPages) {
            start = Math.max(1, start - ((page + half) - totalPages));
        }

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    };

    const visiblePages = getVisiblePages();

    return (
        <div className={styles.container}>
            <button
                onClick={handlePrevClick}
                className={`${styles.page_btn} ${styles.arrow_btn}`}
                disabled={page === 1}
            >
                <div className="pe-4"><Image className='' src={arrowLeft} alt="Left icon" /></div>
            </button>
            {visiblePages.map((pageNumber) => (
                <button
                    onClick={() => onClick(pageNumber)}
                    className={
                        page === pageNumber
                            ? `${styles.page_btn} ${styles.active}`
                            : styles.page_btn
                    }
                    key={pageNumber}
                >
                    {pageNumber}
                </button>
            ))}
            <button
                onClick={handleNextClick}
                className={`${styles.page_btn} ${styles.arrow_btn}`}
                disabled={page === totalPages}
            >
                <div className="ps-4"><Image className='' src={arrowRight} alt="Right icon" /></div>
            </button>
        </div>
    );
};

export default Pagination;