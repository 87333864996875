import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormLabel, Image, InputGroup, Row } from 'react-bootstrap'
import { BiHide, BiShow } from 'react-icons/bi';
import profile from '../assests/smallupload.png'
import { MdOutlineFileUpload } from 'react-icons/md'
import { ProfileInfo, profileUser } from '../services/profile/profile.service'
import { toast } from 'react-toastify'
import Heading from '../components/sharedComponent/Heading'
const Profile = () => {
    const [email, setEmail] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validatecurrentPassword = (currentPassword) => {
        return currentPassword.length >= 8 && currentPassword.length <= 20;
    };
    const validatePassword = (password) => {
        return password.length >= 8 && password.length <= 20;
    };
    const validateConfirmPassword = (confirmPassword) => {
        return password === confirmPassword;
    };
    const handleImageChange = async (e) => {
        const toastId = toast.loading("Updating Image", { closeButton: true });
        const file = e.target.files[0];
        e.target.value = null;
        if (file) {
            try {
                const formData = new FormData();
                formData.append('image', file);
                const response = await profileUser(formData);
                const newImageSrc = response.data.data.imageUrl; // Assuming response contains the new image source
                setImageUrl(newImageSrc);
                toast.dismiss(toastId);
                const currentUser = JSON.parse(localStorage.getItem("user")) || {};
                const updatedUser = {
                    ...currentUser,
                    imageUrl: newImageSrc,
                };

                localStorage.setItem("user", JSON.stringify(updatedUser));
                window.dispatchEvent(new Event('user-update'));
                handleSignUp();

            } catch (error) {
                toast
                    .update(toastId, {
                        render: error?.response?.data?.error,
                        type: "error",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
            }
        }
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('');
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
        setCurrentPasswordError('');
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setconfirmPasswordError('');
    };
    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const ProfileData = async () => {
        try {
            const response = await ProfileInfo();
            console.log("response Profile", response)
            setImageUrl(response?.data?.data?.imageUrl);
            setLastName(response?.data?.data?.lastname)
            setName(response?.data?.data?.firstname)
        } catch (error) {

            console.error("Error deleting item:", error);
        }
    };
    const handleSignUp = async () => {
        // e.preventDefault();
        setNameError('');
        setLastNameError('');
        let isValid = true;
        if (isValid) {
            const toastId = toast.loading("Updating Profile", { closeButton: true });
            try {
                await profileUser({
                    imageUrl: imageUrl,
                    firstname: name,
                    lastname: lastName,
                });

                const currentUser = JSON.parse(localStorage.getItem("user")) || {};
                const updatedUser = {
                    ...currentUser,
                    firstname: name,
                    lastname: lastName,
                };

                localStorage.setItem("user", JSON.stringify(updatedUser));
                window.dispatchEvent(new Event('user-update'));
                toast.update(toastId, {
                    render: "Profile is updated Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                ProfileData();
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    const handleChnagePassword = async (e) => {
        e.preventDefault();
        setEmailError('');
        setCurrentPasswordError('');
        setPasswordError('');
        setconfirmPasswordError('');

        let isValid = true;
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }

        if (!validateConfirmPassword(confirmPassword)) {
            setconfirmPasswordError('Password and Confirm Password should Match.');
            isValid = false;
        }
        if (!validatecurrentPassword(currentPassword)) {
            setCurrentPasswordError('Current Password must be between 8 and 20 characters, must contain at least one uppercase and one special character.');
            isValid = false;
        }
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters, must contain at least one uppercase and one special character.');
            isValid = false;
        }

        if (isValid) {
            const toastId = toast.loading("Updating password", { closeButton: true });
            try {
                const response = await profileUser({
                    email: email,
                    oldPassword: currentPassword,
                    newPassword: password,
                });
                const user = response.data.data;
                localStorage.setItem("user", JSON.stringify(user));
                toast.update(toastId, {
                    render: "Password is updated Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setEmail('')
                setCurrentPassword('')
                setPassword('')
                setConfirmPassword('')
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    useEffect(() => {
        ProfileData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='pt-4'>
            <Row className='mx-0 justify-content-center'>
                <Col xl={7} lg={9} md={8}>
                    <div>
                        <Card className='align-items-center py-4'>
                            <Card.Body>
                                <div className='position-relative d-flex justify-content-center align-items-center flex-column'>
                                    <div className="outer-div-img d-flex justify-content-center align-items-center">
                                        {imageUrl ? (
                                            <Image
                                                src={imageUrl}
                                                alt="user"
                                                className="Image"
                                                roundedCircle={true}
                                                style={{ objectFit: 'cover' }}
                                            />
                                        ) : (
                                            <Image
                                                src={profile} 
                                                alt="default user"
                                                className="Image"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className='camera-icon '>
                                            <label className="">
                                                <div className='upload-icon'>
                                                    <Button
                                                        variant="primary"
                                                        className="Upload-btn d-flex justify-content-center align-items-center px-lg-5 py-lg-2"
                                                        onClick={() => document.getElementById('fileInputProfile').click()}
                                                    >
                                                        <MdOutlineFileUpload className="me-2" size={22} /> Upload Photo
                                                    </Button>
                                                </div>
                                                <input
                                                    type="file"
                                                    id='fileInputProfile'
                                                    onChange={handleImageChange}
                                                    accept="image/jpeg, image/png"
                                                    style={{ display: 'none' }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <p className='course-content mb-0 text-center'>Image size should be under 1MB and</p>
                                    <p className='course-content mb-0 text-center'>image ration needs to be 1:1</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='pt-4'>
                        <Form>
                            <Row>
                                <FormLabel className='font-14' for="email">First name</FormLabel>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicFirst" className='pb-3'>
                                        <Form.Control type="text" placeholder="First Name" className='input-field' value={name} onChange={handleNameChange} />
                                        <Form.Text className="text-danger">{nameError}</Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicLast" className='pb-3'>
                                        <Form.Control type="text" placeholder="Last Name" className='input-field' value={lastName} onChange={handleLastNameChange} />
                                        <Form.Text className="text-danger">{lastNameError}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Button className='d-flex align-items-center gap-4 login-button primary-bg me-lg-0 me-2 py-2 px-3' onClick={handleSignUp}>
                                        <div className=' start-btn-text text-small'>Save Changes</div>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className='py-4'>
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <div className='py-4'>
                                        <Heading heading='Change password' />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="formBasicEmail" className='pb-4'>
                                        <FormLabel className='font-14' for="email">Email</FormLabel>
                                        <div>
                                            <p className='mt-2 mb-0'>{email}</p>
                                        </div>
                                        {/* <Form.Control type="email" className='input-field' placeholder="Email" value={email} onChange={handleEmailChange} /> */}
                                        <Form.Text className="text-danger">{emailError}</Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="formBasicPassword" className="pb-3">
                                        <FormLabel className="font-14" >Current Password</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type={showCurrentPassword ? 'text' : 'password'}
                                                placeholder="Current Password"
                                                value={currentPassword}
                                                onChange={handleCurrentPasswordChange}
                                                aria-label="Current Password"
                                                aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Text onClick={toggleCurrentPasswordVisibility}>
                                                {showCurrentPassword ? <BiHide /> : <BiShow />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text className="text-danger">{currentPasswordError}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group controlId="formBasicPassword" className="pb-3">
                                        <FormLabel className="font-14" >New Password</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="New Password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                aria-label="New Password"
                                                aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Text onClick={togglePasswordVisibility}>
                                                {showPassword ? <BiHide /> : <BiShow />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text className="text-danger">{passwordError}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group controlId="formBasicConfirmPassword" className="pb-4">
                                        <FormLabel className="font-14" >Confirm Password</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                                aria-label="Confirm Password"
                                                aria-describedby="basic-addon3"
                                            />
                                            <InputGroup.Text onClick={toggleConfirmPasswordVisibility}>
                                                {showConfirmPassword ? <BiHide /> : <BiShow />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Button className='d-flex align-items-center gap-4 login-button primary-bg me-lg-0 me-2 py-2 px-3' onClick={handleChnagePassword}>
                                        <div className=' start-btn-text text-small'>change Password</div>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Profile
