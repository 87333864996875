import React from 'react'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate= useNavigate();
    const goBack = () => {
        navigate("/")
    };
    return (
        <div className="page-wrap d-flex flex-row align-items-center" style={{ minHeight: '100vh' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">404</span>
                        <div className="mb-4 lead">The page you are looking for was not found.</div>
                        <button className="btn btn-sm btn-outline-primary" onClick={goBack}>Back to Home</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
