import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assests/header-logo.png'
import { Link, NavLink } from "react-router-dom";
import { Button, Image, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { AuthVerify } from '../../utils/auth.utils';
function Header() {
    const verify = AuthVerify();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="px-2 px-lg-5 py-2 py-lg-4 navbar-bg">
                <Container fluid className='px-0 px-lg-5'>
                    <Navbar.Brand as={Link} to={`/`}>
                        <Image src={logo} width="100%" height="100%" alt="" className='logo-img' style={{ objectFit: 'contain' }} />
                    </Navbar.Brand>
                    <Button variant="link" className="d-lg-none pink-hamburger" onClick={handleShow}>
                        <span className="navbar-toggler-icon"></span>
                    </Button>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m-auto gap-2 gap-lg-5 poppins-semibold">
                            <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} to={'/'}>Home</NavLink>
                            <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/solutions'}>Solutions </NavLink>
                            <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/blogs'}>Blogs</NavLink>
                            {/* <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/lms'}>LMS</NavLink> */}
                            <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/contact'}>Contact</NavLink>
                        </Nav>
                        <Nav className='gap-4 align-items-center poppins-semibold'>
                            {verify ? (
                                <>
                                    <Button as={Link} to={'/dashboard'} variant="primary" type="submit" className='w-100 primary-bg login-button px-4 py-2 font-14'>
                                        Go to Dashboard
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Link to={'/login'} className='text-decoration-none text-dark'>Login</Link>
                                    <Button as={Link} to={'/sign-up'} variant="primary" type="submit" className='w-100 primary-bg login-button px-4 py-2 font-14'>
                                        Register
                                    </Button>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Offcanvas show={show} onHide={handleClose} placement="end" className="fade-offcanvas landing-side">
                <Offcanvas.Header className='primary-transparent ' closeButton>
                    <Offcanvas.Title className='text-black'> <Navbar.Brand as={Link} to={`/`}>
                        <Image src={logo} width="100%" height="100%" alt="" className='logo-img' style={{ objectFit: 'contain' }} />
                    </Navbar.Brand></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='py-4 px-0 '>
                    <div className="d-flex flex-column ms-auto gap-3">
                        <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} to={'/'}>Home</NavLink>
                        <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/solutions'}>Solutions</NavLink>
                        <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/blogs'}>Blogs</NavLink>
                        {/* <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/lms'}>LMS</NavLink> */}
                        <NavLink className={({ isActive }) => `nav-link pb-2 px-3 ${isActive ? 'active-link' : 'inactive-link'}`} as={Link} to={'/contact'}>Contact</NavLink>
                        {verify ? (
                            <>
                                <Button as={Link} to={'/sign-up'} variant="primary" type="submit" className='w-50 primary-bg login-button py-2 font-14 mx-3'>
                                    Go to Dashboard
                                </Button>
                            </>
                        ) : (
                            <>
                                <Link to={'/login'} className='text-decoration-none text-dark px-3'>Login</Link>
                                <Button as={Link} to={'/sign-up'} variant="primary" type="submit" className='w-50 primary-bg login-button px-4 py-2 font-14 mx-3'>
                                    Register
                                </Button>
                            </>

                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>

    );
}

export default Header;