import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { CreateReview } from '../../services/createCourse/createCourse.service';

const FeedbackModal = ({ courseId, onHide, show }) => {
    const [feedbackText, setFeedbackText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        onHide();
    }

    const handleSave = async () => {
        if (feedbackText.trim() === '') {
            return;
        }

        setIsLoading(true);
        const toastId = toast.loading("Adding Feedback", { closeButton: true });
        try {
            await CreateReview({ feedback: feedbackText, courseId });
            toast.update(toastId, {
                render: "Feedback added successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setIsLoading(false);
            handleClose();
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.message,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setIsLoading(false);
        }
    }

    const handleTextareaChange = (event) => {
        setFeedbackText(event.target.value);
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='feedback-header'>Write a Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label className='feedback-label mb-2 '>Feedback:</label>
                            <textarea
                                className="form-control"
                                rows="5"
                                placeholder="Write down your feedback here..."
                                value={feedbackText}
                                onChange={handleTextareaChange}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={handleClose} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={isLoading || feedbackText.trim() === ''}
                        className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                    >
                        Submit Review <RiSendPlane2Fill className="ms-2" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FeedbackModal;
