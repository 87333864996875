import { useNavigate } from 'react-router-dom';

export default function useLogout() {
    let navigate = useNavigate();

    const logoutUser = async () => {
        await localStorage.removeItem("user");
        navigate('/');
    }

    return {
        logoutUser
    }

}
