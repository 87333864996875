import React, { useEffect, useState } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, ComposedChart, Legend } from 'recharts';
import { GetChartData } from '../../services/TeacherDashboard/Dashboard.service';

const LineChartGraph = () => {
    const [chartData, setChartData] = useState([]);
    const [opacity, setOpacity] = useState({
        uv: 1,
        pv: 1,
    });

    const handleMouseEnter = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    };

    const handleMouseLeave = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    };
    const sampledata = [
        { x: 'Sun', totalCourse: 4000, activeCourse: 2400 },
        { x: 'Mon', totalCourse: 3000, activeCourse: 1398 },
        { x: 'Tue', totalCourse: 2000, activeCourse: 9800 },
        { x: 'Wed', totalCourse: 2780, activeCourse: 3908 },
        { x: 'Thu', totalCourse: 1890, activeCourse: 4800 },
        { x: 'Friday', totalCourse: 2390, activeCourse: 3800 },
        { x: 'Sat', totalCourse: 3490, activeCourse: 4300 },
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetChartData();
                console.log("Data Chart", response.data.data)
                if (response.data.success) {
                    setChartData(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <ResponsiveContainer width="100%" height={420}>
            <ComposedChart
                data={chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                <XAxis dataKey="x" interval="preserveStartEnd" padding={{ left: 20, right: 20, }} />
                <YAxis />
                <Tooltip />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                <Area type="monotone" dataKey="totalCourse" strokeOpacity={opacity.pv} stroke="#564FFD" fill="#F7F6FF" />
                <Line type="monotone" dataKey="activeCourse" strokeOpacity={opacity.uv} stroke="#FF6636" />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LineChartGraph;
