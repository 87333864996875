import React from 'react'
import CourseHeading from '../sharedComponent/CourseHeading'
// import CourseContent from '../sharedComponent/CourseContent'
import { Card, Col, Image, Row } from 'react-bootstrap'
import checkcircle from '../../assests/CheckCircle.svg'
import arrowright from '../../assests/ArrowRight.svg'
const Overview = ({ description, courseRequirements, outline, whoThisCourseIsFor }) => {
    const filteredRequirements = courseRequirements?.filter(requirement => requirement && requirement?.trim());
    const filteredOutline = outline?.filter(outline => outline && outline?.trim());
    const filteredWhoThisCourseIsFor = whoThisCourseIsFor?.filter(course => course && course?.trim());
    return (
        <div className='pt-4'>
            <div>
                {description &&
                    <>
                        <CourseHeading heading={"Description"} />
                        <div className='course-content' dangerouslySetInnerHTML={{ __html: description }} />
                    </>
                }
            </div>
            {filteredOutline?.length > 0 && (
                <div className='py-4'>
                    <Card className='Course-card px-lg-3'>
                        <Card.Body className=''>
                            <Card.Title>
                                <CourseHeading heading={"What you will learn in this course"} />
                            </Card.Title>
                            <Card.Text>
                                <Row className='mx-0 pt-3'>
                                    {filteredOutline?.map((stat, index) => (
                                        <Col md={6} key={index} className='d-flex gap-lg-3 gap-2 pb-2 px-0'>
                                            <div className='svg-size'>
                                                <Image src={checkcircle} width={'24px'} height={'24px'} alt='stat' />
                                            </div>
                                            <div>
                                                <p className='poppins-regular' style={{ color: "#4E5566" }}>{stat}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
            )}
            {filteredWhoThisCourseIsFor?.length > 0 && (
                <div className='py-4'>
                    <CourseHeading heading={"Who this course is for"} />
                    <div className='pt-4'>
                        {filteredWhoThisCourseIsFor?.map((stat, index) => (
                            <Col md={12} key={index} className='d-flex gap-3 pb-2'>
                                <div className='svg-size'>
                                    <Image src={arrowright} width={'24px'} height={'24px'} alt='stat' />
                                </div>
                                <div>
                                    <p className='poppins-regular' style={{ color: "#4E5566" }}>{stat}</p>
                                </div>
                            </Col>
                        ))}
                    </div>
                </div>
            )}
            {filteredRequirements?.length > 0 && (
                <div>
                    <CourseHeading heading={"Course requirements"} />
                    <ul className='pt-4'>
                        {filteredRequirements?.map((requirement, index) => (
                            <Col md={12} key={index} className='d-flex gap-3 pb-2'>
                                <div>
                                    <li>
                                        <p className='poppins-regular' style={{ color: "#4E5566" }}>
                                            {requirement}
                                        </p>
                                    </li>
                                </div>
                            </Col>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default Overview
