import React from "react";
import { Col, Offcanvas } from "react-bootstrap";
import { AccordionExample } from "../sharedComponent/AccordionExample";
import CustomCheckbox from "../sharedComponent/Checkbox";
import { AccordianImage } from "../sharedComponent/AccordianImage";
// import icon1 from "../assests/allCourses/icon-1.svg";
import icon1 from "../../assests/allCourses/icon-1.svg"
import icon2 from "../../assests/allCourses/icon-2.svg";
import icon3 from "../../assests/allCourses/icon-3.svg";
const FilterOffcanvas = ({
    show,
    handleClose,
    categories,
    checkedValues,
    handleCheckboxChange,
}) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Col xl={12} className="px-0">
                    <AccordionExample title="CATEGORY">
                        {categories.map((category, index) => (
                            <AccordianImage
                                key={category._id}
                                title={category.name}
                                icon={index === 0 ? icon1 : index === 1 ? icon2 : icon3}
                            >
                                <div className="pt-4">
                                    {category.subcategories.map((subcategory) => (
                                        <CustomCheckbox
                                            key={subcategory._id}
                                            label={subcategory.name}
                                            value={subcategory._id}
                                            checked={checkedValues.subcategoryId?.includes(
                                                subcategory._id
                                            )}
                                            onChange={() =>
                                                handleCheckboxChange("subcategoryId", subcategory._id)
                                            }
                                        />
                                    ))}
                                </div>
                            </AccordianImage>
                        ))}
                    </AccordionExample>
                    <AccordionExample title="Course Level">
                        <div className="pt-4">
                            <CustomCheckbox
                                label="Beginner"
                                value="beginner"
                                checked={checkedValues.level?.includes("beginner")}
                                onChange={() => handleCheckboxChange("level", "beginner")}
                            />
                            <CustomCheckbox
                                label="Intermediate"
                                value="intermediate"
                                checked={checkedValues.level?.includes("intermediate")}
                                onChange={() => handleCheckboxChange("level", "intermediate")}
                            />
                            <CustomCheckbox
                                label="Expert"
                                value="expert"
                                checked={checkedValues.level?.includes("expert")}
                                onChange={() => handleCheckboxChange("level", "expert")}
                            />
                        </div>
                    </AccordionExample>
                    <AccordionExample title="Duration">
                        <div className="pt-4">
                            <CustomCheckbox
                                label="6-12 Months"
                                value="6-12-months"
                                checked={checkedValues.duration?.includes("6-12-months")}
                                onChange={() => handleCheckboxChange("duration", "6-12-months")}
                            />
                            <CustomCheckbox
                                label="3-6 Months"
                                value="3-6-months"
                                checked={checkedValues.duration?.includes("3-6-months")}
                                onChange={() => handleCheckboxChange("duration", "3-6-months")}
                            />
                            <CustomCheckbox
                                label="1-3 Months"
                                value="1-3-months"
                                checked={checkedValues.duration?.includes("1-3-months")}
                                onChange={() => handleCheckboxChange("duration", "1-3-months")}
                            />
                            <CustomCheckbox
                                label="1-4 Weeks"
                                value="1-4-weeks"
                                checked={checkedValues.duration?.includes("1-4-weeks")}
                                onChange={() => handleCheckboxChange("duration", "1-4-weeks")}
                            />
                            <CustomCheckbox
                                label="1-7 Days"
                                value="1-7-days"
                                checked={checkedValues.duration?.includes("1-7-days")}
                                onChange={() => handleCheckboxChange("duration", "1-7-days")}
                            />
                        </div>
                    </AccordionExample>
                </Col>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default FilterOffcanvas;
