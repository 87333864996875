import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Image, FormLabel, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BiHide, BiShow } from 'react-icons/bi';
// import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import logo from '../assests/logo.png'
import { SignUpUser } from '../services/siignup/signUp.service';
import { CiCalendar } from 'react-icons/ci';
const Signup = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');
    // const [rememberMe, setRememberMe] = useState(false);
    const [agree, setAgree] = useState(false);
    const [agreeError, setAgreeError] = useState('');
    const navigate = useNavigate();
    const interviewDateRef = useRef();
    const handleInterviewDateClick = () => {
        interviewDateRef.current.focus();
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validatePassword = (password) => {
        return password.length >= 8 && password.length <= 20;
    };
    const validateConfirmPassword = (confirmPassword) => {
        return password === confirmPassword;
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };
    const handleBirthChange = (e) => {
        setDateOfBirth(e.target.value);
        setDateOfBirthError('');
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };
    const handleAgreeChange = (e) => {
        setAgree(e.target.checked)
        setAgreeError('');
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setconfirmPasswordError('');
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('');
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleSignUp = async (e) => {
        setLoading(true);
        e.preventDefault();
        setNameError('');
        setEmailError('');
        setPasswordError('');
        setconfirmPasswordError('');

        let isValid = true;
        if (!agree) {
            setAgreeError("Please agree Terms and Privacy policy")
            isValid = false;
        }
        // if (dateOfBirth.trim() === '') {
        //     setDateOfBirthError('Please enter your Date of birth.');
        //     isValid = false;
        // }
        // if (lastName.trim() === '') {
        //     setLastNameError('Please enter your last name.');
        //     isValid = false;
        // }
        if (name.trim() === '') {
            setNameError('Please enter your first name.');
            isValid = false;
        }

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }

        if (!validateConfirmPassword(confirmPassword)) {
            setconfirmPasswordError('Password and Confirm Password should Match.');
            isValid = false;
        }

        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters.');
            isValid = false;
        }

        if (isValid) {
            const toastId = toast.loading("Creating User", { closeButton: true });
            try {
                await SignUpUser({
                    firstname: name,
                    lastname: lastName,
                    email: email,
                    dateOfBirth: dateOfBirth,
                    password: password,
                });
                toast.update(toastId, {
                    render: "User created succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                navigate(`/login`)
            } catch (error) {
                console.log("signup error", error)
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };
    return (
        <div className="login-bg d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col lg={8} md={10}>
                        <div style={{ margin: "auto", width: "200px", marginBottom: "50px" }}>
                            <Link to={"/"}>
                                <Image src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div className='sign-heading text-center pb-3'>
                            <p className='poppins-semibold font-28 mb-1'>Create account</p>
                            {/* <p className='poppins-regular font-14' style={{ color: '#2D3748' }}>For Online Studying , band or celebrity.</p> */}
                        </div>
                        <Form>
                            <Row>

                                <Col md={6}>
                                    <Form.Group controlId="formBasicEmail" className='pb-md-0 pb-3 form-height'>
                                        <FormLabel className='font-14' for="email">First name</FormLabel>
                                        <Form.Control type="text" placeholder="First Name" value={name} onChange={handleNameChange} />
                                        <Form.Text className="text-danger">{nameError}</Form.Text>
                                    </Form.Group>




                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicEmail" className='pb-md-0 pb-3 form-height'>
                                        <FormLabel className='font-14' for="email">Last name</FormLabel>
                                        <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={handleLastNameChange} />
                                        <Form.Text className="text-danger">{lastNameError}</Form.Text>
                                    </Form.Group>




                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicEmail" className='pb-md-0 pb-3 form-height'>
                                        <FormLabel className='font-14' for="email">Email</FormLabel>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                        <Form.Text className="text-danger">{emailError}</Form.Text>
                                    </Form.Group>




                                </Col>

                                <Col md={6}>
                                    <Form.Group controlId="formBasicEmail" className='pb-md-0 pb-3 form-height calender-input'>
                                        <FormLabel className='font-14' for="email">Date of birth (MM/DD/YY)</FormLabel>
                                        <div className="position-relative">
                                            <Form.Control type="date" placeholder="Date of birth" ref={interviewDateRef} value={dateOfBirth} onChange={handleBirthChange} />
                                            <div className="char-count"><CiCalendar onClick={handleInterviewDateClick} /></div>
                                        </div>
                                        <Form.Text className="text-danger">{dateOfBirthError}</Form.Text>
                                    </Form.Group>




                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formBasicPassword" className="">
                                        <FormLabel className="font-14" htmlFor="newPassword">Password</FormLabel>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                aria-label="New Password"
                                                aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Text id="basic-addon2" onClick={togglePasswordVisibility}>
                                                {showPassword ? <BiHide /> : <BiShow />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text className="text-danger">{passwordError}</Form.Text>
                                    </Form.Group>

                                    <div className='text-muted pb-2'>
                                        <small>
                                            <li>Password must be between 8 and 20 characters</li>
                                            <li>Contain at least one uppercase</li>
                                            <li>And one special character.</li>
                                        </small>
                                    </div>


                                </Col>

                                <Col md={6}>


                                    <Form.Group controlId="formBasicPassword" className='pb-md-0 pb-3 form-height'>
                                        <FormLabel className='font-14' for="email">Confirm Password</FormLabel>
                                        <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                        <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>
                                    </Form.Group>

                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md={6}>
                                    <Row className='justify-content-between pb-3'>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicCheckbox" className=''>
                                                <Form.Check type="checkbox" className='custom-check' label="Remember me" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row className='d-none d-lg-block'>
                                        <Col md={12} className='text-end pb-2'>
                                            <Link to={'/forgot-password'} variant="link" className='p-0 font-14 primary-color'>
                                                Forgot Password?
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col md={6}>
                                    <Row className='justify-content-between pb-3'>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasic" className='font-14'>
                                                <Form.Check type="checkbox" className='custom-check' label={<span className='font-14'>I agree to all the <Link to={'/terms-and-conditions'} className='primary-color text-decoration-none'>Terms & Conditons</Link></span>} checked={agree} onChange={handleAgreeChange} />
                                                <Form.Text className="text-danger">{agreeError}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    {/* <Row className='d-block d-lg-none'>
                                        <Col md={6} className='pb-2'>
                                            <Link to={'/forgot-password'} variant="link" className='p-0 font-14 primary-color'>
                                                Forgot Password?
                                            </Link>
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col md={6}>
                                    <Button variant="primary" type="submit" onClick={handleSignUp} className='w-100 primary-bg login-button py-2 font-14 my-1 my-lg-0' disabled={loading}>
                                        {loading ? 'Creating...' : 'Create Account'}
                                    </Button>
                                </Col>
                                {/* <Col md={6}>
                                    <Col md={12}>
                                        <Button type='submit' className="d-flex align-items-center justify-content-center gap-2 w-100 py-2 font-14 sign-in-google-btn my-1 my-lg-0" >
                                            <FcGoogle size={16} />
                                            Sign-in with google
                                        </Button>
                                    </Col>
                                </Col> */}
                            </Row>
                            <div className="col-lg-12 new-user text-center mt-md-4 mt-3 mb-md-0 mb-md-3 font-14">
                                <p className="mb-0">Already have an account?
                                    <Link className="ps-1 text-decoration-none primary-color log-in-link" to={`/login`}>Log In</Link>
                                </p>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Signup;
