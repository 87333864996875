import React from 'react'

const CourseHeading = ({ heading }) => {
    return (
        <div>
            <h3 className='course-Subheading poppins-semibold'>{heading}</h3>
        </div>
    )
}

export default CourseHeading
